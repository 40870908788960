import { useContext } from 'react'
import { X } from '@phosphor-icons/react'
import { DateTime } from 'luxon'

import { SL_Button } from '../../../components/buttons/Button'

import { colors } from '../../../services/style'
import { displayMessage } from '../../../helpers/messages'
import { GeneralContext } from '../../../context/GeneralContext/GeneralContext'

import exportToExcel from '../../../services/export'

export const ModalTitle = ({ onCloseButtonClick, title, data, columns }) => {
    const { mobile_mode } = useContext(GeneralContext)

    const onExportClick = () => {
        const now = DateTime.now().toFormat('dd/MM/yyyy')
        const reportTitle = `${title}-${now}.xlsx`
        const columnsToExport = columns.filter((col) => !col.noExport)
        const reportData = data.map((action) => {
            const object = {}
            columnsToExport.forEach((col) => {
                object[col.title] = action[col.dataIndex]
            })

            return object
        })
        exportToExcel(reportData, reportTitle)
    }
    return (
        <div
            style={{ borderBottom: `1px solid ${colors['sl-gray'][100]}` }}
            className={`w-full h-[100px] flex p-6 text-white ${mobile_mode ? 'flex-col gap-1' : 'text-lg justify-between items-center'} `}
        >
            <div className="flex gap-3">
                <X size={25} color={colors['sl-gray'][50]} className="cursor-pointer" onClick={onCloseButtonClick} />
                <h3 className="text-medium text-[25px]/[28px]">{title}</h3>
            </div>
            <SL_Button type="degault" className="bg-white/10 hover:bg-white/20 h-[40px] w-[122px] rounded-[8px] px-4" onClick={() => onExportClick()}>
                {displayMessage('CSV_EXPORT')}
            </SL_Button>
        </div>
    )
}
