import { useContext } from 'react'
import { Outlet } from 'react-router-dom'
// import logo from '../../../assets/logo.png'
import { colors } from '../../../services/style'
import { GeneralContext } from '../../../context/GeneralContext/GeneralContext'
import { ConfigProvider } from 'antd'

import './ExternalLayout.css'

export const ExternalLayout = ({ children }) => {
    const { mobile_mode } = useContext(GeneralContext)

    return (
        <div className="w-screen h-screen flex justify-between items-center bg-sl-gray-900">
            {/* <div className="h-full bg-sl-gray-900 none justify-center items-center flex-[2] hidden smb:flex">
                <img src={logo} className="w-full max-w-[700px] min-w-[200px]" />
            </div> */}

            <div className="h-full flex justify-center items-center flex-1 relative bg-secondary-600 smb:min-w-[450px]" style={{ boxShadow: !mobile_mode && '-5px 0px 13px 0px rgba(156,156,156,1)' }}>
                <ConfigProvider
                    theme={{
                        token: {
                            colorError: colors['sl-red'][200],
                        },
                    }}
                >
                    <div className="flex flex-col items-center flex-1 p-5">{children ? children : <Outlet />}</div>
                </ConfigProvider>
            </div>
        </div>
    )
}
