import { ConfigProvider, Modal } from 'antd'
import { colors } from '../../services/style'

export function SL_Modal({ children, open, width, footer, onCancel, bodyStyle, title = '', defaultTitleProps = null, maskClosable, baseColor = colors['sl-gray'][1000], centered = true, ...rest }) {
    return (
        <ConfigProvider
            theme={{
                token: {
                    colorBgBase: baseColor,
                    colorText: colors['sl-gray'][50],
                    colorTextPlaceholder: colors['sl-gray'][300],
                    colorIcon: colors['sl-gray'][50],
                },
            }}
        >
            <Modal
                open={true}
                centered={centered}
                maskClosable={false}
                width={width ?? 600}
                onCancel={onCancel}
                bodyStyle={bodyStyle}
                footer={footer}
                {...rest}
                title={
                    <div className="text-white" {...defaultTitleProps}>
                        {title}
                    </div>
                }
            >
                {children}
            </Modal>
        </ConfigProvider>
    )
}
