import { useContext, useEffect, useState } from 'react'

import { SL_Modal } from '../../components/modal/Modal'
import { ModalTitle } from './components/ModalTitle'
import { ChoseCentral } from './components/ChoseCentral'
import { ReportTable } from './components/ReportTable'
import { ShowSVG } from '../../components/showSVG/ShowSVG'

import { GeneralContext } from '../../context/GeneralContext/GeneralContext'
import { displayMessage } from '../../helpers/messages'
import api from '../../services/api'

export const AlertLogsModal = ({ onClose }) => {
    const { windowHeight, windowWidth } = useContext(GeneralContext)
    const [actions, setActions] = useState(null)
    const [central, setCentral] = useState(null)

    const [showMore, setShowMore] = useState(true)
    const [showLoading, setShowLoading] = useState(false)

    const modalHeigth = windowHeight * 0.7
    const limit = 40

    const columns = [
        {
            dataIndex: 'icon',
            title: '',
            width: 60,
            ellipsis: true,
            noExport: true,
        },
        {
            dataIndex: 'description',
            title: displayMessage('DESCRIPTION'),
            width: 300,
            ellipsis: true,
        },
        {
            dataIndex: 'status_name',
            title: displayMessage('STATUS'),
            width: 200,
            ellipsis: true,
        },
        {
            dataIndex: 'first_name',
            title: displayMessage('FIRST_NAME'),
            width: 200,
            ellipsis: true,
        },
        {
            dataIndex: 'painel_date',
            title: displayMessage('DATE_AND_HOUR'),
            width: 200,
            ellipsis: true,
        },

        {
            dataIndex: 'sector',
            title: displayMessage('ZONE_TITLE'),
            width: 200,
            ellipsis: true,
        },
        {
            dataIndex: 'location',
            title: displayMessage('LOCATION_TITLE'),
            width: 240,
            ellipsis: true,
        },
    ]

    const getReportData = async (params = { limit, ofsett: 0 }, id = central) => {
        setShowLoading(true)
        try {
            params = {
                ...params,
                painel: true,
            }
            const { data } = await api.alertLogReports.reportsByCentral(id, params)

            const newActions = data.map((action) => ({ ...action, icon: <ShowSVG icon={action.icon} height={30} width={40} /> }))
            setActions((current) => (!current ? newActions : current.concat(newActions)))
            if (limit > data.length) {
                setShowMore(false)
            }
        } catch (error) {
            console.log(error)
        } finally {
            setShowLoading(false)
        }
    }

    useEffect(() => {
        if (central) {
            setActions(null)
            setShowMore(true)
            getReportData(null, central)
        }
    }, [central])

    return (
        <SL_Modal
            open={true}
            title={<ModalTitle onCloseButtonClick={onClose} title={displayMessage('ALERT_LOGS_TITLE')} data={actions} columns={columns} />}
            closable={false}
            closeIcon={null}
            footer={null}
            width={windowWidth * 0.9}
            bodyStyle={{
                height: modalHeigth,
                padding: 12,
            }}
        >
            <ChoseCentral onChangeCentral={setCentral} />
            {actions && <ReportTable columns={columns} data={actions} height={modalHeigth - 100} onShowMore={getReportData} showMoreOptions={showMore} loading={showLoading} limit={limit} />}
        </SL_Modal>
    )
}
