import { useContext } from "react"
import { ConfigProvider } from "antd"

import backIcon from '../../../assets/icons/ArrowLeft.svg'

import { SL_Button } from "../../../components/buttons/Button"

import { displayMessage } from "../../../helpers/messages"
import { GeneralContext } from "../../../context/GeneralContext/GeneralContext"

export const NewTitle = ({title, showButtons, back, form }) => {
    const { mobile_mode } = useContext(GeneralContext)

    return (
        <ConfigProvider
            theme={{
                components: {
                    Button: {
                        lineWidth: 0,
                        primaryShadow: '0 0 0',
                    },
                },
            }}
        >
            <div className={`flex justify-between items-center w-full ${mobile_mode ? 'flex-col' : ''}`}>
                <div className={`flex gap-3 items-center justify-center ${mobile_mode ? '' : 'text-lg'}`}>
                    <img src={backIcon} className=" cursor-pointer" width={24} height={24} onClick={() => back()} />
                    <span className="text-white mb-2 mx-2 text-[25px]/[25px]">{title}</span>
                </div>
                {showButtons && (
                    <div className="flex gap-3 items-start p-1">
                        <SL_Button
                            type="default"
                            className="bg-white/10 hover:bg-white/20 text-white w-[92px] h-[32px] rounded-[32px] py-[10px]  px-4 flex justify-center items-center"
                            onClick={() => back()}
                        >
                            {displayMessage('CANCEL')}
                        </SL_Button>
                        <SL_Button
                            type="default"
                            className="bg-primary-600 hover:bg-primary-600/80 text-white w-[92px] h-[32px] rounded-[32px] py-[10px] px-4 flex justify-center items-center"
                            onClick={() => form.submit()}
                        >
                            {displayMessage('SAVE')}
                        </SL_Button>
                    </div>
                )}
            </div>
        </ConfigProvider>
    )
}
