import { Checkbox, ConfigProvider } from 'antd'
import { colors } from '../../services/style'

export function SL_Checkbox({ children, ...rest }) {
    return (
        <ConfigProvider
            theme={{
                token: {
                    colorBgContainer: colors['sl-gray'][150],
                    colorBorder: colors['sl-gray'][50],
                },
            }}
        >
            <Checkbox {...rest}>{children}</Checkbox>
        </ConfigProvider>
    )
}
