import { axiosInstance } from './axiosInstance'

const path = {
    auth: 'api/painel/auth',
    users: 'api/painel/user',
    companies: 'api/painel/company',
    userActionLogs: '/api/painel/user-action-logs',
    centrals: 'api/painel/centrals',
    alertLogs: 'api/painel/alert-logs',
    activationGroups: '/api/painel/activation-groups',
    userCentral: '/api/painel/user-centrals',
    monitorings: '/api/painel/monitorings',
    groups: '/api/painel/groups',
    activeAlerts: '/api/painel/active-alerts',
    batteryLevelReports: '/api/painel/battery-level-reports',
    alertTypeSolutions: '/api/painel/alert-type-solution',
    alertLogReports: '/api/painel/alert-log-reports',
    // expoTokens: "api/expo-tokens",
    // sectors: "/api/sectors",
    // notifications: '/api/notifications',
}

export default {
    auth: {
        login: (body) => {
            return axiosInstance.post(`${path.auth}/authenticate`, body)
        },
        recoverPassword: (body) => {
            return axiosInstance.post(`${path.auth}/recover-password`, body)
        },
        updatePasswordFirstLogin: (id, body) => {
            return axiosInstance.put(`${path.auth}/change-password-first-login/${id}`, body)
        },
        changePassword: (body) => {
            return axiosInstance.put(`${path.auth}/change-password`, body)
        },
        deactivate: (body) => {
            return axiosInstance.put(`${path.auth}/deactivate`, body)
        },
        currentUser: () => {
            return axiosInstance.get(`${path.auth}/current-user`)
        },
        removeSocketConection: (token) => {
            return axiosInstance.post(`${path.auth}/remove-socket/${token}`)
        },
    },
    users: {
        // changeStatusByAdmin: (user_id, body) => {
        //     return axiosInstance.put(`${path.auth}/change-user-status-by-admin/${user_id}`, body)
        // }
        create: (body) => {
            return axiosInstance.post(`${path.users}/`, body)
        },
        list: (params) => {
            return axiosInstance.get(`${path.users}/`, { params })
        },
        delete: (id) => {
            return axiosInstance.delete(`${path.users}/${id}`)
        },
        update: (id, body) => {
            return axiosInstance.put(`${path.users}/${id}`, body)
        },
    },
    companies: {
        create: (body) => {
            return axiosInstance.post(`${path.companies}/`, body)
        },
        list: (params) => {
            return axiosInstance.get(`${path.companies}/`, {params})
        },
        delete: (id) => {
            return axiosInstance.delete(`${path.companies}/${id}`)
        },
        update: (id, body) => {
            return axiosInstance.put(`${path.companies}/${id}`, body)
        },
    },
    userActionLogs: {
        reportsByCentral: (id, params) => {
            return axiosInstance.get(`${path.userActionLogs}/reports-by-central/${id}`, { params })
        },
    },
    centrals: {
        list: () => {
            return axiosInstance.get(`${path.centrals}/`)
        },
        listbyUserAccess: () => {
            return axiosInstance.get(`${path.centrals}/user-acess`)
        },
        prevDataToManageCentrals: (companyId) => {
            return axiosInstance.get(`${path.centrals}/prev-data-to-manage-centrals/${companyId}`)
        },
        manage: (companyId, body) => {
            return axiosInstance.post(`${path.centrals}/manage-central/${companyId}`, body)
        },
        delete: (id) => {
            return axiosInstance.delete(`${path.centrals}/${id}`)
        },
        create: (body) => {
            return axiosInstance.post(`${path.centrals}/`, body)
        },
        update: (id, body) => {
            return axiosInstance.put(`${path.centrals}/${id}`, body)
        },
    },
    alertLogs: {
        monitoring: (params) => {
            return axiosInstance.get(`${path.alertLogs}/monitoring`, { params })
        },
        lastAlertsByTime: (params) => {
            return axiosInstance.get(`${path.alertLogs}/last-alerts`, { params })
        },
        getByCentral: (central_id) => {
            return axiosInstance.get(`${path.alertLogs}/by-central/${central_id}`)
        },
        systemActions: (type, body) => {
            return axiosInstance.put(`${path.alertLogs}/system-actions/${type}`, body)
        },
        // reports: (id, params) => {
        //     return axiosInstance.get(`${path.alertLogs}/reports-by-central/${id}`, { params })
        // },
    },
    activationGroups: {
        listByCentral: (id) => {
            return axiosInstance.get(`${path.activationGroups}/by-central/${id}`)
        },
    },
    userCentral: {
        listByCentral: (id, params) => {
            return axiosInstance.get(`${path.userCentral}/users-by-central/${id}`, params)
        },
        delete: (user_id, central_id) => {
            return axiosInstance.delete(`${path.userCentral}/${user_id}/${central_id}`)
        },
        vinculate: (body) => {
            return axiosInstance.post(`${path.userCentral}/`, body)
        },
        prevDataToManageCentrals: (user_id) => {
            return axiosInstance.get(`${path.userCentral}/prev-data-to-manage-centrals/${user_id}`)
        },
        manage: (user_id, body) => {
            return axiosInstance.post(`${path.userCentral}/manage-centrals/${user_id}`, body)
        },
        //     listCentrals: params => {
        //         return axiosInstance.get(`${path.userCentral}/centrals-by-user`, params)
        //     },
        //     loadCurrentCentral: () => {
        //         return axiosInstance.get(`${path.userCentral}/current-central`)
        //     },
        //     updateCurrentCentral: (id, params) => {
        //         return axiosInstance.put(`${path.userCentral}/current-central/${id}`, params)
        //     }
    },
    monitorings: {
        create: (body) => {
            return axiosInstance.post(`${path.monitorings}/`, body)
        },
        sendEmails: (body) => {
            return axiosInstance.post(`${path.monitorings}/send-emails`, body)
        },
        getPrevData: (alert_id) => {
            return axiosInstance.get(`${path.monitorings}/prev-data/${alert_id}`)
        },
    },
    groups: {
        create: (body) => {
            return axiosInstance.post(`${path.groups}/`, body)
        },
        list: () => {
            return axiosInstance.get(`${path.groups}/`)
        },
        find: (id) => {
            return axiosInstance.get(`${path.groups}/${id}`)
        },
        delete: (id) => {
            return axiosInstance.delete(`${path.groups}/${id}`)
        },
        update: (id, body) => {
            return axiosInstance.put(`${path.groups}/${id}`, body)
        },
        prevDataToManageCentrals: (groupId) => {
            return axiosInstance.get(`${path.groups}/prev-data-to-manage-centrals/${groupId}`)
        },
        manage: (groupId, body) => {
            return axiosInstance.post(`${path.groups}/manage-centrals/${groupId}`, body)
        },
    },
    activeAlerts: {
        reportsByCentral: (id, params) => {
            return axiosInstance.get(`${path.activeAlerts}/reports-by-central/${id}`, { params })
        },
        // loadSystemActionButtons: () => {
        //     return axiosInstance.get(`${path.activeAlerts}/system-action-buttons`)
        // },
        // alerts: (id, params) => {
        //     return axiosInstance.get(`${path.activeAlerts}/alerts-by-central/${id}`, { params } )
        // },
    },
    batteryLevelReports: {
        reportsByCentral: (id, params) => {
            return axiosInstance.get(`${path.batteryLevelReports}/reports-by-central/${id}`, { params })
        },
    },
    alertTypeSolutions: {
        reportsByCentral: (id, params) => {
            return axiosInstance.get(`${path.alertTypeSolutions}/reports-by-central/${id}`, { params })
        },
    },
    alertLogReports: {
        reportsByCentral: (id, params) => {
            return axiosInstance.get(`${path.alertLogReports}/reports-by-central/${id}`, { params })
        },
    },
    // sectors: {
    //     list: centralId => {
    //         return axiosInstance.get(`${path.sectors}/${centralId}`)
    //     }
    // },
    // notifications: {
    //     readAlerts: (centralId) => {
    //         return axiosInstance.put(`${path.notifications}/read-alerts/${centralId}`, )
    //     }
    // },
}
