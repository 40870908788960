import React from 'react'
import './index.css'
import { ConfigProvider } from 'antd'
import { default as ptBR_antd } from 'antd/es/locale/pt_BR'
import './App.css'

import { LicenseInfo, ptBR } from '@mui/x-data-grid-pro'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { ptBR as ptBR_mui } from '@mui/material/locale'

import { GeneralContextProvider } from './context/GeneralContext/GeneralContextProvider'
import { AuthContextProvider } from './context/AuthContext/AuthContextProvider'
import { Router } from './routes/Router'
import { colors } from './services/style'

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_KEY)

function App() {
    const theme = createTheme({ palette: { primary: { main: colors.primary[500] } } }, ptBR, ptBR_mui)

    return (
        <ConfigProvider
            locale={ptBR_antd}
            theme={{
                token: {
                    colorPrimary: colors.primary[500],
                    borderRadius: 4,
                    colorTextDisabled: colors.primary[500],
                },
            }}
        >
            <ThemeProvider theme={theme}>
                <AuthContextProvider>
                    <GeneralContextProvider>
                        <Router />
                    </GeneralContextProvider>
                </AuthContextProvider>
            </ThemeProvider>
        </ConfigProvider>
    )
}

export default App
