import { useContext, useEffect, useState } from 'react'
import { Badge, ConfigProvider, Tabs } from 'antd'

import { SL_Modal } from '../../../../components/modal/Modal'
import { ShowSVG } from '../../../../components/showSVG/ShowSVG'

import { displayMessage } from '../../../../helpers/messages'
import { getColorByLuminosity, getIcon, hexToRgba } from '../../../../helpers/helper'
import { GeneralContext } from '../../../../context/GeneralContext/GeneralContext'
import { colors } from '../../../../services/style'
import { ECentralActions } from '../../../../enums/ECentralActions'

export const CentralModal = ({ data, onClose, onClickUserActionButton }) => {
    const { windowHeight, windowWidth, mobile_mode } = useContext(GeneralContext)

    const modalWidth = mobile_mode ? windowWidth * 0.9 : null
    const [sectorActive, setSectorActive] = useState(data?.sectors?.[0])

    const generateAlertStyles = (sector) => {
        const sectorsStyle = {
            backgroundColor: '',
            border: '',
        }

        const activeStyles = {
            backgroundColor: hexToRgba(sector.devices?.[0]?.color, 0.4),
            border: `solid 1.5px ${sector.devices?.[0]?.color}`,
        }
        return sector.alert_counts ? activeStyles : sectorsStyle
    }

    const generalActions = Object.values(ECentralActions)
    const sectors = data.sectors.map((sector) => ({
        label: (
            <div id={`sector-${sector.id}`} className="flex gap-1 p-1 rounded" style={generateAlertStyles(sector)}>
                {sector.name}
                {sector.alert_counts ? <Badge count={sector.alert_counts} color={sector.devices?.[0]?.color} style={{ color: 'white', boxShadow: 'none' }} /> : null}
            </div>
        ),
        key: sector.id,
    }))

    const onChangeTab = (value) => {
        const active = data.sectors.find((sec) => sec.id == value)
        setSectorActive(active)
    }

    const title = (
        <div className={`flex justify-between ${mobile_mode && 'flex-col'} p-3 min-h-[50px] text-white`}>
            <span className="mt-2">{data.central_name}</span>

            <ConfigProvider
                theme={{
                    token: {
                        colorBorder: 'transparent',
                        colorBorderSecondary: 'transparent',
                        colorPrimaryBorder: 'transparent',
                    },
                    components: {
                        Tabs: {
                            itemSelectedColor: colors['sl-gray'][50],
                            itemActiveColor: colors['sl-gray'][50],
                            itemHoverColor: colors['sl-gray'][50],
                            inkBarColor: colors['sl-gray'][50],
                            horizontalItemPadding: '0px 0px 5px 0px',
                            horizontalItemGutter: 10,
                        },
                    },
                }}
            >
                <Tabs
                    defaultActiveKey={data?.sectors?.[0]?.key}
                    className={`${mobile_mode ? 'max-w-full' : 'max-w-[300px]'} h-[35px] mr-8`}
                    items={sectors}
                    onTabClick={onChangeTab}
                    activeKey={sectorActive?.key}
                />
            </ConfigProvider>
        </div>
    )

    const getGeneralButtonColor = (key) => {
        const { should_activate_general_alert_buttton, should_activate_group_alert_button } = data.general_buttons_data

        switch (key) {
            case ECentralActions.GENERAL_ALERT.code:
                if (should_activate_general_alert_buttton) return 'bg-sl-red-200'
                break
            case ECentralActions.GROUP_ALERT.code:
                if (should_activate_group_alert_button) return 'bg-sl-red-200'
                break
        }
        return 'gradient-button'
    }

    useEffect(() => {
        onChangeTab(data?.sectors?.[0]?.id)
    }, [])

    return (
        <SL_Modal
            open={true}
            closable={true}
            closeIcon={null}
            footer={
                <div className={`grid gap-2 grid-cols-2 sm:grid-cols-5 pt-4`} style={{ borderTop: `1.5px solid ${colors['sl-gray'][150]}` }}>
                    {Object.values(data.count_by_alert).map((alert) => (
                        <div className="flex flex-col">
                            <div className="flex justify-center items-center gap-2">
                                {getIcon(alert.type, alert.color)}
                                {alert.quantity ? (
                                    <div style={{ backgroundColor: alert.color, color: getColorByLuminosity(alert.color) }} className="rounded-full px-2 text-xs">
                                        <span className="font-bold">{alert.quantity}</span>
                                    </div>
                                ) : null}
                            </div>
                            <div
                                className="flex justify-center items-center uppercase pb-5 mx-3"
                                // style={alert.quantity ? { boxShadow: ` inset 10 -15px 5px -5px ${hexToRgba(alert.color, 0.5)}` } : {}}
                            >
                                <span style={{ fontSize: 10 }}>{alert.label}</span>
                            </div>
                            <div
                                style={
                                    alert.quantity
                                        ? {
                                              boxShadow: `${hexToRgba(alert.color, 0.5)} 0px -6px 8px 4px`,
                                              height: 1,
                                              margin: '0px 10px',
                                              backgroundColor: 'transparent',
                                          }
                                        : { display: 'none' }
                                }
                            ></div>
                        </div>
                    ))}
                </div>
            }
            width={modalWidth}
            title={title}
            onCancel={onClose}
            bodyStyle={{}}
        >
            <div className="flex flex-col gap-3 -mt-4">
                <div className={`grid gap-2 grid-cols-2 sm:grid-cols-4 p-4 `} style={{ borderTop: `1.5px solid ${colors['sl-gray'][150]}`, borderBottom: `1.5px solid ${colors['sl-gray'][150]}` }}>
                    {generalActions.map((action) => (
                        <div
                            key={action.label}
                            onClick={() => onClickUserActionButton(action.code)}
                            className={`h-[50px] rounded flex flex-col gap-0.5 items-center justify-center p-1 w-full cursor-pointer text-sm ${getGeneralButtonColor(action.code)}`}
                        >
                            <img src={action.icon} height={15} width={15} />
                            <span className="text-xs">{action.label}</span>
                        </div>
                    ))}
                </div>
                <div className="px-4">
                    <div className={`${sectorActive?.devices.length ? 'grid gap-2 grid-cols-2' : ''} custom-scroll pr-1 -mr-2`} style={{ height: windowHeight * 0.4, overflow: 'auto' }}>
                        {sectorActive?.devices.length ? (
                            sectorActive?.devices.map((device) => (
                                <div className="flex gradient-button h-[80px] rounded p-1 w-full items-center">
                                    <div className="rounded m-1 flex items-center justify-center h-[55px]" style={{ backgroundColor: device.color }}>
                                        <ShowSVG width={50} height={40} icon={device.icon} />
                                    </div>
                                    <div key={device.key} className={`flex flex-col`}>
                                        <span>{device.first_name}</span>
                                        <span>{device.second_name}</span>
                                        <span>{displayMessage('LOCATION', [device.location_description ?? displayMessage('EMPTY')])}</span>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <div className="h-full w-full flex justify-center items-center"> {displayMessage('NO_DEVICES')}</div>
                        )}
                    </div>
                </div>
            </div>
        </SL_Modal>
    )
}
