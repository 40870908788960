import { useContext } from 'react'
import { Switch } from 'antd'

import { Central } from './Central'
import { Group } from './Group'
import { CentralSvg } from '../../../components/icons/CentralSvg'

import { GeneralContext } from '../../../context/GeneralContext/GeneralContext'
import { displayMessage } from '../../../helpers/messages'
import { colors } from '../../../services/style'

export const Centrals = ({ centrals, onOpenCentral, onOpenGroup, onFilterByAlerts, justActiveAlerts, onChangeVisibility, centralsToGroup, fromGroup = null }) => {
    const { windowHeight } = useContext(GeneralContext)

    const lastAlertsHeigth = document.getElementById('lastAlerts')?.offsetHeight || 0
    const headerHeigth = document.getElementById('header')?.offsetHeight || 0
    const safeArea = 5

    const height = windowHeight - headerHeigth - lastAlertsHeigth

    return (
        <div style={{ height }} className={`overflow-auto custom-scroll relative ${fromGroup ? '' : 'px-1'}`}>
            {justActiveAlerts && !centrals.length ? (
                <div className="flex flex-col justify-center items-center h-full w-full gap-2 text-slate-300">
                    <CentralSvg color={colors['sl-gray'][300]} />
                    <span>{displayMessage('NO_ALERT_CENTRALS')}</span>
                </div>
            ) : (
                <div className={`${fromGroup ? 'relative' : ''} flex flex-wrap gap-4 m-2 h-[85%]`}>
                    {centrals.map((central, i) =>
                        central.is_group ? (
                            <Group key={'group_id_' + central.group_id} data={central} onOpenGroup={onOpenGroup} centrals={centralsToGroup} />
                        ) : (
                            <Central index={i} key={'central_id_' + central.central_id} data={central} onOpenCentral={onOpenCentral} onChangeVisibility={onChangeVisibility} fromGroup={fromGroup} />
                        )
                    )}
                </div>
            )}

            <div className={`flex justify-center items-center  w-full ${fromGroup ? 'absolute' : 'fixed'}`} style={{ bottom: fromGroup ? 0 : lastAlertsHeigth + safeArea }}>
                <div className="flex gap-3 py-3 pl-6 pr-4 rounded-[120px] w-[207px] h-[44px] items-center" style={{ border: `1px solid ${colors['sl-transparency'][12]}` }}>
                    <Switch
                        size="small"
                        onChange={onFilterByAlerts}
                        id={'focus_view'}
                        className="w-[32px] h-[20px] rounded-[100px] "
                        style={{ border: `1px solid ${colors['sl-transparency'][24]}` }}
                    />
                    <span className="text-[14px]/[14px] text-medium">{displayMessage('FOCUS_VEWER')}</span>
                </div>
            </div>
        </div>
    )
}
