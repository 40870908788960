import React, { useState, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { Form, Image } from 'antd'
import api from '../../services/api'
import { displayMessage } from '../../helpers/messages'
import { GeneralContext } from '../../context/GeneralContext/GeneralContext'
import { SL_Button } from '../../components/buttons/Button'
import { SL_Input } from '../../components/input/Input'
import { ForgotPasswordModal } from './components/ForgotPasswordModal'
import { AuthContext } from '../../context/AuthContext/AuthContext'
import { colors } from '../../services/style'
import { PersonCircleOutline, LockClosedOutline, EyeOutline, EyeOffOutline } from 'react-ionicons'
import { EAlertTypes } from '../../enums/EAlertTypes'

export const Login = () => {
    const [isForgotPasswordModalOpen, setIsForgotPasswordModalOpen] = useState(false)
    const [showLoading, setShowLoading] = useState(false)
    const [showSuccessInfoInForgotPasswordModal, setShowSuccessInfoInForgotPasswordModal] = useState(false)
    const [visiblePassword, setVisiblePassword] = useState(false)
    const { onChangeIsLogged } = useContext(AuthContext)
    const [emailSended, setEmailSended] = useState(false)

    const navigate = useNavigate()

    const { showAlert } = useContext(GeneralContext)

    const onFinish = async ({ email, password }) => {
        try {
            const loged = await onChangeIsLogged(true, { email, password })

            if(loged) navigate('/')
            
        } catch (error) {
            console.log(error)
        }
    }

    const handleOkPasswordRecovery = async (email) => {
        if (!email) {
            showAlert('INVALID_EMAIL')
            return
        }

        setShowLoading(true)
        try {
            await api.auth.recoverPassword({ email })
            setEmailSended(true)
            showAlert(displayMessage('SUCCESS_SEND_EMAIL'), EAlertTypes.SUCCESS)
        } catch (error) {
            console.log(error)
        } finally {
            setShowLoading(false)
        }
    }

    const onCloseModalForgotPassword = () => {
        if (showLoading) {
            return
        }

        setShowSuccessInfoInForgotPasswordModal(false)
        setIsForgotPasswordModalOpen(false)
    }

    const togglePasswordVisibility = () => {
        setVisiblePassword(!visiblePassword)
    }

    return (
        <>
            {isForgotPasswordModalOpen && (
                <ForgotPasswordModal
                    emailSended={emailSended}
                    onOk={handleOkPasswordRecovery}
                    onClose={onCloseModalForgotPassword}
                    showLoading={showLoading}
                    showSuccessInfoInForgotPasswordModal={showSuccessInfoInForgotPasswordModal}
                />
            )}

            <>
                <Form onFinish={onFinish} className="max-w-[300px] w-full" layout="vertical" requiredMark={false}>
                    <div className="flex justify-center">
                        <Image preview={false} src={require('../../assets/logo-official.jpg')} className="rounded-3xl" width={100} />
                    </div>

                    <div className="flex justify-center items-center flex-col gap-y-3 mt-5">
                        <div className="text-white text-2xl">{displayMessage('ALARM_MONITOR')}</div>
                        <div className="text-white font-bold text-3xl">{displayMessage('GLOBAL_SONIC')}</div>
                    </div>

                    <div className="flex flex-col gap-1">
                        <div className="text-sl-gray-50 font-bold shadow-title">{displayMessage('EMAIL')}</div>

                        <Form.Item name="email" rules={[{ required: true, message: displayMessage('DISPLAY_EMAIL') }]} className="mb-4">
                            <SL_Input
                                // prefix={<MailOutlined className="site-form-item-icon mr-2" />}
                                prefix={<PersonCircleOutline color={'#ffffff'} style={{ marginTop: 5 }} />}
                                placeholder={displayMessage('EMAIL')}
                                className={'w-full font-bold shadow-md'}
                            />
                        </Form.Item>
                    </div>

                    <div className="flex flex-col gap-1 mt-1">
                        <div className="text-sl-gray-50 font-bold shadow-title">{displayMessage('PASSWORD')}</div>

                        <Form.Item name="password" rules={[{ required: true, message: displayMessage('DISPLAY_PASSWORD') }]} className="mb-6">
                            <SL_Input
                                prefix={<LockClosedOutline color={'#ffffff'} style={{ marginTop: 5 }} />}
                                suffix={
                                    visiblePassword ? (
                                        <EyeOutline color={'#ffffff'} style={{ marginTop: 5 }} onClick={() => togglePasswordVisibility()} />
                                    ) : (
                                        <EyeOffOutline color={'#ffffff'} style={{ marginTop: 5 }} onClick={() => togglePasswordVisibility()} />
                                    )
                                }
                                type={visiblePassword ? 'text' : 'password'}
                                className={'w-full font-bold shadow-md'}
                                placeholder={displayMessage('PASSWORD')}
                            />
                        </Form.Item>
                    </div>

                    <Form.Item hasFeedback className="mb-3 mt-2">
                        <SL_Button type="primary" className={'w-full font-bold shadow-md bg-primary-500'} htmlType="submit" style={{ backgroundColor: colors.primary[100] }}>
                            {displayMessage('ENTER')}
                        </SL_Button>
                    </Form.Item>

                    <div
                        className="flex justify-end text-white underline cursor-pointer"
                        onClick={() => {
                            setIsForgotPasswordModalOpen(true)
                        }}
                    >
                        {/* <div
                            className='text-white underline cursor-pointer'
                            onClick={() => {
                                setIsForgotPasswordModalOpen(true)
                            }}
                        >
                        </div> */}
                        {displayMessage('FORGOT_PASSWORD')}
                    </div>
                </Form>
            </>
        </>
    )
}
