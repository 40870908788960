import { displayMessage } from "../../../helpers/messages"
import { Option } from "./components/Option"

export const NotificationsOption = () => {
  const options = [
    {
      title: displayMessage("REPORT"),
      description: displayMessage("REPORT_DESCRIPTION"),
      checked: true,
      onChange: () => {}
    },
    {
      title: displayMessage("POPUP_REPORT"),
      description: displayMessage("POPUP_REPORT_DESCRIPTION"),
      checked: true,
      onChange: () => {}
    },
    {
      title: displayMessage("SEND_REPOR_BY_EMAIL"),
      description: displayMessage("SEND_REPOR_BY_EMAIL_DESCRIPTION"),
      checked: true,
      onChange: () => {}
    },
    {
      title: displayMessage("SYSTEM_ALERTS"),
      description: displayMessage("SYSTEM_ALERTS_DESCRIPTION"),
      checked: false,
      onChange: () => {}
    },
  ]
  return (
    <div className="flex flex-col  gap-3">
      {
        options.map(option => <Option {...option} />)
      }
    </div>
  )
}