import { useContext, useEffect, useState } from 'react'
import { Dropdown } from 'antd'

import arrowIcon from '../../../assets/icons/arrow_down.svg'

import { displayMessage } from '../../../helpers/messages'

import { ETimes } from '../../../enums/ETimes'
import { ECentralAlertTypes } from '../../../enums/ECentralAlertTypes'

import { GeneralContext } from '../../../context/GeneralContext/GeneralContext'
import { getIconByCode } from '../../../helpers/helper'
import { colors } from '../../../services/style'
import api from '../../../services/api'

export const LastAlerts = ({ data, time, onChangeTime, onChangeAlertsValue, onClickAlert }) => {
    const { mobile_mode } = useContext(GeneralContext)
    const items = Object.values(ETimes).map((t) => {
        return {
            ...t,
            key: t.value,
            label: <span className="text-white text-[14px]/[14px]">{t.label}</span>,
        }
    })

    const alertTypes = {}
    Object.values(ECentralAlertTypes).forEach((cat) => {
        alertTypes[cat.code] = cat
    })

    const onChangeTimeValue = async (value) => {
        onChangeTime(value)
        try {
            const lastAlertsWidth = document.getElementById('lastAlerts')?.offsetWidth || 0
            const safeArea = 100
            const sizeAlert = 446
            const limit_for_last_alerts = Math.floor((lastAlertsWidth - safeArea) / sizeAlert) * 2
            const { data } = await api.alertLogs.lastAlertsByTime({ time: value, limit_for_last_alerts })
            onChangeAlertsValue(data)
        } catch (error) {
            console.log(error)
        }
    }

    const getData = (code, data) => {
        return alertTypes[code][data]
    }

    const getSelectedTime = () => {
        const selectedTime = Object.values(ETimes).find((t) => t.value == time)?.label

        return selectedTime || ETimes.TODAY.label
    }

    return (
        <div className="bg-sl-gray-1000 -mx-2 -mb-2 p-10 flex flex-col gap-3  h-[249px]" id="lastAlerts">
            <div className="flex justify-between items-center">
                <span className="text-sl-gray-0 uppercase text-[14px]/[14px] opacity-40">{displayMessage('LAST_ALERTS')}</span>

                <Dropdown menu={{ items, style: { backgroundColor: colors['sl-gray'][1000], width: '150px', color: 'white' }, onClick: (e) => onChangeTimeValue(e.key) }}>
                    <div className="flex gap-[6px] items-center">
                        <div className="opacity-70 text-[14px]/[14px]">{getSelectedTime()}</div>
                        <img src={arrowIcon} width={20} height={20} />
                    </div>
                </Dropdown>
            </div>

            {data.length ? (
                <div className={`flex gap-2 flex-wrap `}>
                    {data.map((alert) => (
                        <div
                            key={`alert-${alert.id}`}
                            className="flex justify-between items-center rounded-lg p-3 cursor-pointer text-xs bg-sl-gray-100 h-[56px] w-[446px]"
                            style={{ border: `solid 1px ${colors['sl-gray'][150]}` }}
                            onClick={() => onClickAlert(alert.id)}
                        >
                            <div className="flex items-center gap-[10px]">
                                <div className="rounded-full p-1 bg-sl-gray-100 flex justify-center items-center h-[32px] w-[32px]" style={{ border: `solid 1px ${colors['sl-gray'][150]}` }}>
                                    {getIconByCode(alert.type, alert.color, 20)}
                                </div>
                                <div className="flex flex-col uppercase gap-1">
                                    <span style={{ color: alert.color }} className="text-[11px]/[11px]">
                                        {getData(alert.type, 'label')}
                                    </span>
                                    <span className="text-[14px]/[14px]">{alert.label}</span>
                                </div>
                            </div>

                            <div className="flex items-center gap-[10px] opacity-40">
                                <div className="flex flex-col items-end gap-1">
                                    <span className="text-sl-gray-0 text-[11px]/[11px]">{alert.date}</span>
                                    <span className="text-sl-gray-0 text-[11px]/[11px]">{alert.minutes}</span>
                                </div>
                                <span className="px-2 py-1 rounded-[5px] gradient-button w-[46px] h-[24px] text-[12px]/[16px]" style={{ border: `1px solid ${colors['sl-gray'][150]}` }}>
                                    Abrir
                                </span>
                            </div>
                        </div>
                    ))}
                </div>
            ) : (
                <div className="flex justify-center items-center h-[50px] text-white">{displayMessage('NO_ALERTS_ON_PERIOD')}</div>
            )}
        </div>
    )
}
