export function openSocketConnection({ host, user_id }, { onOpen, onClose, onReceiveMessage }) {
    if (!host) {
        console.error('Invalid host!')
        return
    }

    if (!user_id) {
        console.error('Invalid user id!')
        return
    }

    if (!onOpen && !onClose && !onReceiveMessage) {
        console.error('No function provided. Socket not created!')
        return
    }

    const token = localStorage.getItem('websocket_token')

    const newSocket = new WebSocket(`${host}/socket?user_id=${user_id}&token=${token}`)

    newSocket.addEventListener('open', () => {
        console.log('Conexão aberta!')

        if (onOpen) {
            onOpen()
        }
    })

    newSocket.addEventListener('message', (event) => {
        console.log(`Mensagem recebida: ${event.data}`)

        if (onReceiveMessage) {
            onReceiveMessage(event)
        }
    })

    newSocket.addEventListener('close', (e) => {
        console.log('Conexão fechada!')

        if (onClose) {
            onClose()
        }
    })

    return newSocket
}
