import { useEffect, useState } from 'react'
import { Tag } from 'antd'
import { ArrowUpOutlined } from '@ant-design/icons'

import logo from '../../../assets/logo-writed.svg'
import { getIconByCode, hexToRgba } from '../../../helpers/helper'
import { ECentralAlertTypes } from '../../../enums/ECentralAlertTypes'
import { ECentralActions } from '../../../enums/ECentralActions'
import { displayMessage } from '../../../helpers/messages'
import { colors } from '../../../services/style'

export const Header = ({ alerts, centrals, handleNotificationClick }) => {
    const [prioritaryAlert, setPrioritaryAlert] = useState(null)
    const [count, setCount] = useState(0)

    const generalActions = [ECentralActions.GENERAL_ALERT, ECentralActions.GROUP_ALERT, ECentralActions.SILENCE]

    const alert_type_label = Object.values(ECentralAlertTypes).find((type) => type.code == prioritaryAlert?.alert_type)?.label || ''

    const handleNotifications = (alerts_to_show) => {
        if (!alerts_to_show) {
            setPrioritaryAlert(null)
            setCount(0)
            return
        }
        const prioritary = alerts_to_show.sort((a, b) => a.priority - b.priority)[0]

        setPrioritaryAlert(prioritary)
        let newCount = -1
        alerts_to_show.forEach((al) => {
            Object.values(al.alerts).forEach((type) => {
                newCount += type.quantity
            })
        })
        setCount(newCount)
    }

    useEffect(() => {
        const centrals_ids = centrals.map((central) => central.central_id)
        const alerts_to_show = alerts ? Object.values(alerts).filter((alert) => !alert.isVisible && centrals_ids.includes(alert.central_id)) : null
        handleNotifications(alerts_to_show)
    }, [alerts, centrals])

    return (
        <div
            className="flex justify-between items-center text-white flex-wrap gap-2 pt-4 p-3 -mt-2 -mx-2"
            id="header"
            style={prioritaryAlert && { background: `linear-gradient(${hexToRgba(prioritaryAlert?.notification_color, 0.5)}, ${colors['sl-gray'][900]})` }}
        >
            <img src={logo} className="h-[50px]"></img>

            {prioritaryAlert && (
                <Tag
                    color={prioritaryAlert.notification_color}
                    className="flex justify-between items-center px-4 rounded-[120px] min-w-[449px] h-[56px] gap-4"
                    style={{
                        boxShadow: `0px 0px 20px 7px ${hexToRgba(prioritaryAlert?.notification_color, 0.5)}`,
                    }}
                >
                    <div className="flex justify-center items-center gap-1 uppercase">
                        {getIconByCode(prioritaryAlert.alert_type, '#ffffff', 40)}
                        <span className="text-[24px]/[24px] uppercase">{alert_type_label}</span>
                    </div>

                    <div className="flex justify-center items-center gap-2 uppercase text-[16px]/[16px]">
                        <span>{prioritaryAlert.central_name}</span>
                        {count > 0 && <span> +{count} </span>}
                    </div>

                    <Tag
                        color="white"
                        className="flex justify-center items-center px-[10px] rounded-[120px] gap-1 cursor-pointer h-[24px] w-[102px] text-[14px]/[14px]"
                        onClick={() => handleNotificationClick()}
                    >
                        <div style={{ color: prioritaryAlert.notification_color, fontSize: 10 }}>{displayMessage('SEE_NOW')}</div>
                        <ArrowUpOutlined style={{ color: prioritaryAlert.notification_color, fontSize: 14 }} />
                    </Tag>
                </Tag>
            )}

            <div></div>
        </div>
    )
}
