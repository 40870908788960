import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { PrivateRoute } from './components/PrivateRoute'
import { InternalLayout } from './components/layouts/InternalLayout'
import { ExternalLayout } from './components/layouts/ExternalLayout'
// import { ResetPasswordForm } from '../screens/resetPassword/ResetPasswordForm'
import { Login } from '../screens/login/Login'
// import { ChangePassword } from '../screens/changePassword/ChangePassword'
import { GeneralRoute } from './components/GeneralRoute'
import { Monitor } from '../screens/monitor/Monitor'

const router = createBrowserRouter([
    {
        path: '/',
        element: <GeneralRoute />,
        children: [
            //Rotas privadas
            {
                path: '/',
                element: <PrivateRoute />,
                children: [
                    {
                        path: '/',
                        element: <InternalLayout />,
                        children: [
                            {
                                index: true,
                                element: <Monitor />,
                            },
                        ],
                    },
                    // {
                    //     path: 'change-password',
                    //     element: (
                    //         <ExternalLayout>
                    //             <ChangePassword />
                    //         </ExternalLayout>
                    //     ),
                    // },
                ],
            },

            //Rotas públicas
            {
                path: 'login',
                element: <ExternalLayout />,
                children: [
                    {
                        index: true,
                        element: <Login />,
                    },
                    // {
                    //     path: 'reset-password/:token',
                    //     element: <ResetPasswordForm />,
                    // },
                ],
            },
        ],
    },
])

export function Router() {
    return <RouterProvider router={router} />
}
