import { useEffect, useState } from 'react'
import { colors } from '../../../services/style'
import { getColorByLuminosity } from '../../../helpers/helper'
import { Central } from './Central'

export const Group = ({ data, onOpenGroup, centrals }) => {
    const color = data?.color || colors['sl-gray'][100]
    const [groupCentrals, setGroupCentrals] = useState([])

    const formatCentrals = () => {
        const centralsOnGroupNumber = 6
        const group_centrals = centrals.filter(central => data?.central_ids?.includes(central.central_id))
        setGroupCentrals(group_centrals.slice(0, centralsOnGroupNumber))
    }

    useEffect(() => {
        formatCentrals()
    }, [centrals])

    return (
        <div
            className="w-[253.71px] h-[256px] rounded-lg relative overflow-auto gradient-button cursor-pointer bg-sl-gray-100"
            onClick={() => onOpenGroup(data)}
            // se descomentar esse estilo a classe gradient-button deve ser substituida por gradient-button-custom
            // style={{ backgroundColor: color, color: getColorByLuminosity(color) }}
        >
            <div className="w-full rounded-t-lg flex items-center justify-center px-1 py-6">
                <span className="truncate font-medium text-[20px]/[20px]">{data.group_name}</span>
            </div>
            <div className={`flex flex-wrap gap-1 mx-3 p-2`}>
                {groupCentrals.map((central, i) => (
                    <Central index={i} key={'litle_central_id_' + central.central_id} data={central} onOpenCentral={() => {}} littleCentral={true} />
                ))}
            </div>
        </div>
    )
}
