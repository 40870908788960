import { useContext, useEffect, useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { AuthContext } from '../../context/AuthContext/AuthContext'

export function GeneralRoute() {
    const [isFirstRender, setIsFirstRender] = useState(true)

    const { loggedUser } = useContext(AuthContext)

    const navigate = useNavigate()

    useEffect(() => {
        if (!loggedUser && !isFirstRender) {
            navigate('/login')
        }

        if (isFirstRender) {
            setIsFirstRender(false)
        }
    }, [loggedUser])

    return <Outlet />
}
