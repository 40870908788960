import React, { useContext, useEffect, useState } from 'react'

import { Centrals } from './components/Centrals'
import { Header } from './components/Header'
import { LastAlerts } from './components/LastAlerts'
import { CentralModal } from './components/centralModal/CentralModal'
import { ModalConfirmUserActions } from './components/centralModal/ModalConfirmUserActions'
import { MonitoringCreationModal } from './components/monitoringCreationModal/MonitoringCreationModal'
import { GroupModal } from './components/groupModal/GroupModal'

import { AuthContext } from '../../context/AuthContext/AuthContext'
import { GeneralContext } from '../../context/GeneralContext/GeneralContext'
import { displayMessage } from '../../helpers/messages'
import { openSocketConnection } from '../../helpers/socket'
import { ETimes } from '../../enums/ETimes'
import { EAlertTypes } from '../../enums/EAlertTypes'
import api from '../../services/api'

export const Monitor = () => {
    const { currentUser } = useContext(AuthContext)
    const { showAlert } = useContext(GeneralContext)
    const [isWebSocketConnected, setIsWebSocketConnected] = useState(false)
    const [isUserActionsOpen, setIsUserActionsOpen] = useState(false)
    const [isMonitoringCreationModalOpen, setIsMonitoringCreationModalOpen] = useState(false)
    const [isGroupModalOpen, setIsGroupModalOpen] = useState(false)

    const [centrals, setCentrals] = useState([])
    const [centralsToGroup, setCentralsToGroup] = useState([])
    const [alerts, setAlerts] = useState([])

    const [openCentralId, setOpenCentralId] = useState(null)
    const [openGroup, setOpenGroup] = useState(null)
    const [openCentral, setOpenCentral] = useState(null)
    const [alertOnMonitoringCreation, setAlertOnMonitoringCreation] = useState(null)
    const [action, setAction] = useState(null)

    const [time, setTime] = useState(ETimes.TODAY.value)
    const [justActiveAlerts, setJustActiveAlerts] = useState(false)
    const [alertsToHeader, setAlertsToHeader] = useState()

    function loadSocketConection() {
        const socket = openSocketConnection(
            { host: process.env.REACT_APP_WEBSOCKET_HOST, user_id: currentUser?.id },
            {
                onOpen: () => setIsWebSocketConnected(true),
                onClose: () => setIsWebSocketConnected(false),
                onReceiveMessage: function (event) {
                    onReceiveSocketMessage(event.data)
                },
            }
        )
        return () => {
            if (socket?.close) {
                socket?.close()
            }
        }
    }

    const onReceiveSocketMessage = async (data) => {
        const message = JSON.parse(data)
        getMonitoringData()
        getCentralData()
        showAlert(
            <div className="flex flex-col">
                {message?.updated_centrals?.map((updated_central, i) => (
                    <span key={`updated-central-${i}`}>{updated_central}</span>
                ))}
            </div>,
            EAlertTypes.INFO,
            displayMessage('SUCCESS_UPDATE_CENTRALS_DATA')
        )
    }

    const getMonitoringData = async (filters = {}) => {
        try {
            const just_active_alerts = document.getElementById('focus_view')?.getAttribute('aria-checked') == 'true'
            const selectedTime = document.getElementById('selected_time')?.innerText
            const lastAlertsWidth = document.getElementById('lastAlerts')?.offsetWidth || 0
            const safeArea = 100
            const sizeAlert = 446
            const limit_for_last_alerts = Math.floor((lastAlertsWidth - safeArea) / sizeAlert) * 2

            const params = { time: selectedTime, just_active_alerts, limit_for_last_alerts, ...filters }

            const { data } = await api.alertLogs.monitoring(params)
            setCentralsToGroup(data.centrals)
            setCentrals(data.centrals)
            setAlerts(data.alerts)
        } catch (error) {
            console.log(error)
        }
    }

    const getCentralData = async (central_id) => {
        try {
            if (!central_id) {
                const element = document.getElementById('opened_central')
                if (element) central_id = element.innerText
            }
            if (!central_id) return
            const { data } = await api.alertLogs.getByCentral(central_id)
            setOpenCentral(data)
        } catch (error) {
            console.log(error)
        }
    }

    const handleOnClickAlert = (alert) => {
        setIsMonitoringCreationModalOpen(true)
        setAlertOnMonitoringCreation(alert)
    }

    const handleOnCLoseMonitoringCreation = () => {
        setIsMonitoringCreationModalOpen(false)
        setAlertOnMonitoringCreation(null)
    }

    const handleChangeFocusVewer = (checked) => {
        const just_active_alerts = checked
        setJustActiveAlerts(checked)
        getMonitoringData({ just_active_alerts })
    }

    const onOpenCentral = (central_id) => {
        setOpenCentralId(central_id)
        getCentralData(central_id)
    }

    const onCloseCentral = () => {
        setOpenCentralId(null)
        setOpenCentral(null)
        if (openGroup) setIsGroupModalOpen(true)
    }

    const onOpenGroup = (group) => {
        setIsGroupModalOpen(true)
        setOpenGroup(group)
    }

    const onCloseGroup = () => {
        setIsGroupModalOpen(false)
        setOpenGroup(null)
    }

    const handleClickUserActionButton = (action_code) => {
        setOpenCentral(null)
        setIsUserActionsOpen(true)
        setAction(action_code)
    }

    const onCloseModalConfirmUserActions = () => {
        setIsUserActionsOpen(false)
        setAction(null)
        getCentralData(openCentralId)
    }

    const onOpenCentralByGroups = (central_id) => {
        setIsGroupModalOpen(false)
        setOpenCentralId(central_id)
        getCentralData(central_id)
    }

    const handleNotificationClick = () => {
        const element = document.getElementById('first_central')
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' })
        }
    }

    useEffect(() => {
        loadSocketConection()
        getMonitoringData()
    }, [])

    return (
        <div className="text-white w-full h-full flex flex-col">
            {openCentral && <CentralModal data={openCentral} onClose={onCloseCentral} onClickUserActionButton={handleClickUserActionButton} />}
            {isGroupModalOpen && <GroupModal centrals={centrals} group={openGroup} onClose={onCloseGroup} onOpenCentral={onOpenCentralByGroups} />}
            {isUserActionsOpen && <ModalConfirmUserActions onClose={() => onCloseModalConfirmUserActions()} modalOption={action} currentCentral={openCentralId} />}
            {isMonitoringCreationModalOpen && <MonitoringCreationModal alert={alertOnMonitoringCreation} onClose={() => handleOnCLoseMonitoringCreation()} />}

            <div className={`hidden`} id="opened_central">
                {openCentralId}
            </div>
            <div className={`hidden`} id="selected_time">
                {time}
            </div>
            <Header alerts={alertsToHeader} centrals={centrals} handleNotificationClick={handleNotificationClick} />
            <Centrals
                centrals={centrals}
                centralsToGroup={centralsToGroup}
                onOpenCentral={onOpenCentral}
                onOpenGroup={onOpenGroup}
                onFilterByAlerts={handleChangeFocusVewer}
                justActiveAlerts={justActiveAlerts}
                onChangeVisibility={setAlertsToHeader}
            />
            <LastAlerts data={alerts} time={time} onChangeTime={setTime} onChangeAlertsValue={setAlerts} onClickAlert={handleOnClickAlert} />
        </div>
    )
}
