import { useContext, useEffect, useState } from 'react'
import { Form } from 'antd'

import userIcon from '../../../assets/icons/user.svg'

import { NewOption } from '../components/NewOption'
import { Option } from '../components/Option'
import { NewUser } from './components/NewUser'
import { NewTitle } from '../components/NewTitle'
import { ManageCentrals } from '../components/ManageCentrals'

import { displayMessage } from '../../../helpers/messages'
import { GeneralContext } from '../../../context/GeneralContext/GeneralContext'
import { EAlertTypes } from '../../../enums/EAlertTypes'

import api from '../../../services/api'
import '../gradients.css'
import { CentralsSection } from '../components/CentralsSection'

export const UsersOption = ({ onChangeTitle, setShowLoading }) => {
    const { mobile_mode } = useContext(GeneralContext)
    const [form] = Form.useForm()
    const { showAlert } = useContext(GeneralContext)
    const [isAdding, setIsAdding] = useState(false)
    const [isEditing, setIsEditing] = useState(false)
    const [isLooking, setIsLooking] = useState(false)
    const [isManaging, setIsManaging] = useState(false)

    const [users, setUsers] = useState(null)
    const [user, setUser] = useState(null)
    const [dataToManage, setDataToManage] = useState(null)
    const actions = {
        ADD: {
            key: 1,
            label: displayMessage('ADD_USER'),
        },
        EDIT: {
            key: 2,
            label: displayMessage('EDIT_USER'),
        },
        REMOVE: {
            key: 3,
            label: displayMessage('REMOVE_USER'),
        },
        SEE: {
            key: 4,
        },
         MANAGE: {
            key: 5,
        },
    }

    const backToInitState = () => {
        setIsAdding(false)
        setIsEditing(false)
        setIsLooking(false)
        setUser(null)
        onChangeTitle(null)
        form.resetFields()
    }

    const handleOnClickAction = (key, user) => {
        if (user) {
            setUser(user)
            if (user.granted_app_access_date && !user.removed_app_access_date) user.granted_app_access = true
            if (user.granted_painel_access_date && !user.removed_painel_access_date) user.granted_painel_access = true
            delete user.password
            form.setFieldsValue(user)
        }
        let newTitle
        let showButtons = true
        let back = () => backToInitState()
        switch (key) {
            case actions.ADD.key:
                newTitle = displayMessage('ADD_USER')
                setIsAdding(true)
                break
            case actions.EDIT.key:
                newTitle = displayMessage('EDIT_USER')

                setIsEditing(true)
                break
            case actions.SEE.key:
                newTitle = user.full_name
                showButtons = false

                setIsLooking(true)
                break
            case actions.MANAGE.key:
                newTitle = displayMessage('CENTRALS_TITLE', [user?.full_name])
                back = () => {
                    setIsManaging(false)
                    const newKey = isEditing ? actions.EDIT.key : actions.SEE.key
                    handleOnClickAction(newKey, user)
                }
                setIsManaging(true)
                manageCentrals()
                break
        }
        onChangeTitle(<NewTitle title={newTitle} showButtons={showButtons} back={() => back()} form={form}/>)
    }

    const createUser = async (values) => {
        setShowLoading(true)
        try {
            await api.users.create(values)
            showAlert(displayMessage('SUCCESS_CREATE_USER'), EAlertTypes.SUCCESS)
            backToInitState()
            getUsers()
        } catch (error) {
            console.log(error)
        } finally {
            setShowLoading(false)
        }
    }

    const editUser = async (values) => {
        setShowLoading(true)
        try {
            await api.users.update(user.id, values)
            showAlert(displayMessage('SUCCESS_EDIT_USER'), EAlertTypes.SUCCESS)
            backToInitState()
            getUsers()
        } catch (error) {
            console.log(error)
        } finally {
            setShowLoading(false)
        }
    }

    const deleteUser = async (id) => {
        setShowLoading(true)
        try {
            await api.users.delete(id)
            showAlert(displayMessage('SUCCESS_DELETE_USER'), EAlertTypes.SUCCESS)
            getUsers()
        } catch (error) {
            console.log(error)
        } finally {
            setShowLoading(false)
        }
    }

    const getUsers = async () => {
        setShowLoading(true)
        try {
            const { data } = await api.users.list()
            setUsers(data)
        } catch (error) {
            console.log(error)
        } finally {
            setShowLoading(false)
        }
    }

    const manageCentrals = async () => {
        setShowLoading(true)
        try {
            const { data } = await api.userCentral.prevDataToManageCentrals(user.id)
            setDataToManage(data)
        } catch (error) {
            console.log(error)
        } finally {
            setShowLoading(false)
        }
    }

    const saveCentralsManagement = async (values) => {
        setShowLoading(true)
        try {
            const { data } = await api.userCentral.manage(user.id, values)
            const newUsers = [...users]
            const newUser = { ...user }
            const idx = newUsers.findIndex((u) => u.id == user.id)
            newUsers[idx].Centrals = data
            newUser.Centrals = data

            setUsers(newUsers)
            form.resetFields()
            setDataToManage(null)
            setIsManaging(false)

            const key = isEditing ? actions.EDIT.key : actions.SEE.key
            handleOnClickAction(key, newUser)
        } catch (error) {
            console.log(error)
        } finally {
            setShowLoading(false)
        }
    }

    useEffect(() => {
        getUsers()
    }, [])

    return (
        <>
            {!isAdding && !isEditing && !isLooking && (
                <div className="flex flex-col gap-2">
                    <NewOption onClickAction={() => handleOnClickAction(actions.ADD.key)} message={displayMessage('CLICK_TO_ADD_A_NEW_USER')} />

                    {users &&
                        users.map((user) => (
                            <Option
                                key={user.key}
                                iconImg={userIcon}
                                onClickTitle={() => handleOnClickAction(actions.SEE.key, user)}
                                title={user.full_name}
                                onClickEdition={() => handleOnClickAction(actions.EDIT.key, user)}
                                onConfirmDelete={() => deleteUser(user.id)}
                                deleteMessage={displayMessage('DELET_CONFIRM_MESSAGE')}
                                editTooltip={actions.EDIT.label}
                                removeTooltip={actions.REMOVE.label}
                            />
                        ))}
                </div>
            )}

            {isAdding && !isManaging && <NewUser sendValues={createUser} form={form} isAdding={true} setShowLoading={setShowLoading} />}
            {isEditing && !isManaging && <NewUser sendValues={editUser} form={form} />}
            {isLooking && !isManaging && <NewUser form={form} onlyView={true} />}
            {(isEditing || isLooking) && !isManaging && <CentralsSection centrals={user?.Centrals} onManageCentrals={() => handleOnClickAction(actions.MANAGE.key, user)} />}
            {isManaging && dataToManage && <ManageCentrals data={dataToManage} form={form} onManageCentrals={saveCentralsManagement} />}
        </>
    )
}
