import { displayMessage } from '../helpers/messages'

import sirenIcon from '../assets/icons/siren.svg'
import sirenGroupIcon from '../assets/icons/sirene-square.svg'
import silenceIcon from '../assets/icons/silence.svg'
import resetIcon from '../assets/icons/reset.svg'

export const ECentralActions = {
    GENERAL_ALERT: {
        label: displayMessage('GENERAL_ALERT'),
        icon: sirenIcon,
        code: 1,
    },
    GROUP_ALERT: {
        label: displayMessage('GROUP_ALERT'),
        icon: sirenGroupIcon,
        code: 2,
    },
    SILENCE: {
        label: displayMessage('SILENCE'),
        icon: silenceIcon,
        code: 4,
    },
    RESET: {
        label: displayMessage('RESET'),
        icon: resetIcon,
        code: 3,
    },
}
