import { useContext, useEffect, useState } from 'react'

import { SL_Modal } from '../../../../components/modal/Modal'
import { Centrals } from '../Centrals'

import { GeneralContext } from '../../../../context/GeneralContext/GeneralContext'
import { getColorByLuminosity } from '../../../../helpers/helper'

export const GroupModal = ({ centrals, group, onClose, onOpenCentral }) => {
    const initialCentralsToShow = centrals.filter((central) => group.central_ids.includes(central.central_id))
    const { windowHeight, windowWidth, mobile_mode } = useContext(GeneralContext)
    const [centralsToShow, setCentralsToShow] = useState(initialCentralsToShow)
    const [justActiveAlerts, setJustActiveAlerts] = useState(false)

    const title = (
        <div className="w-full text-white rounded-t-md p-3 text-center" style={{ backgroundColor: initialCentralsToShow?.[0]?.color, color: getColorByLuminosity(initialCentralsToShow?.[0]?.color) }}>
            {group.group_name}
        </div>
    )

    useEffect(() => {
        if (justActiveAlerts) {
            setCentralsToShow(initialCentralsToShow.filter((central) => central.has_alerts))
        } else {
            setCentralsToShow(initialCentralsToShow)
        }
    }, [justActiveAlerts])
    return (
        <SL_Modal
            open={true}
            closable={true}
            closeIcon={null}
            footer={null}
            width={windowWidth * 0.9}
            title={title}
            onCancel={onClose}
            bodyStyle={{
                padding: 12,
                heigth: windowHeight * 0.7,
                overflow: 'auto',
            }}
        >
            <Centrals centrals={centralsToShow} onOpenCentral={onOpenCentral} onFilterByAlerts={setJustActiveAlerts} justActiveAlerts={justActiveAlerts} onChangeVisibility={null} fromGroup={true} />
        </SL_Modal>
    )
}
