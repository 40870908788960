export const CentralSvg = ({ color, size = 24 }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5 0C3.89543 0 3 0.89543 3 2V22C3 23.1046 3.89543 24 5 24H19C20.1046 24 21 23.1046 21 22V2C21 0.895431 20.1046 0 19 0H5ZM6 2C5.44772 2 5 2.44772 5 3V6C5 6.55228 5.44772 7 6 7H9C9.55228 7 10 6.55228 10 6V3C10 2.44772 9.55228 2 9 2H6ZM11 3C11 2.44772 11.4477 2 12 2H18C18.5523 2 19 2.44772 19 3V6C19 6.55228 18.5523 7 18 7H12C11.4477 7 11 6.55228 11 6V3ZM6 8C5.44772 8 5 8.44772 5 9V15C5 15.5523 5.44772 16 6 16H18C18.5523 16 19 15.5523 19 15V9C19 8.44772 18.5523 8 18 8H6ZM5 18C5 18.5523 5.44772 19 6 19H18C18.5523 19 19 18.5523 19 18C19 17.4477 18.5523 17 18 17H6C5.44772 17 5 17.4477 5 18ZM6 22C5.44772 22 5 21.5523 5 21C5 20.4477 5.44772 20 6 20H18C18.5523 20 19 20.4477 19 21C19 21.5523 18.5523 22 18 22H6Z"
                fill={color}
            />
            <path d="M7 3.5C7 3.77614 6.77614 4 6.5 4C6.22386 4 6 3.77614 6 3.5C6 3.22386 6.22386 3 6.5 3C6.77614 3 7 3.22386 7 3.5Z" fill={color} />
            <path d="M7 18C7 18.2761 6.77614 18.5 6.5 18.5C6.22386 18.5 6 18.2761 6 18C6 17.7239 6.22386 17.5 6.5 17.5C6.77614 17.5 7 17.7239 7 18Z" fill={color} />
            <path d="M7 21C7 21.2761 6.77614 21.5 6.5 21.5C6.22386 21.5 6 21.2761 6 21C6 20.7239 6.22386 20.5 6.5 20.5C6.77614 20.5 7 20.7239 7 21Z" fill={color} />
            <path
                d="M8.85998 14.4724C8.96844 14.1643 9.03612 13.6854 8.72861 13.3927C8.70112 13.5301 8.57606 13.8164 8.2957 13.8635C8.45031 13.4614 8.53277 12.5414 7.62572 12.0785C7.691 12.2845 7.71436 12.7866 7.28557 13.1476C6.89371 13.4774 7.00872 14.0956 7.20363 14.5C6.49533 14.2069 6 13.5346 6 12.7522C6 12.6209 6.01395 12.4927 6.0405 12.3689C6.20133 11.5982 6.64864 11.0788 6.86025 10.8747C6.64018 11.3403 6.76856 11.9384 6.86025 12.1792C6.82146 11.9852 6.85814 11.4889 7.31522 11.0554C7.7723 10.6219 7.61147 9.83783 7.47393 9.5C8.79438 10.1583 9.02574 11.2862 8.97637 11.7678C9.26416 11.6153 9.26557 11.2293 9.2303 11.0554C9.322 11.1758 9.56041 11.5278 9.78049 11.881C9.92169 12.1421 10 12.4383 10 12.7522C10 13.511 9.53415 14.1661 8.85998 14.4724Z"
                fill={color}
            />
            <path d="M12 3.5C12 3.22386 12.2239 3 12.5 3H17.5C17.7761 3 18 3.22386 18 3.5C18 3.77614 17.7761 4 17.5 4H12.5C12.2239 4 12 3.77614 12 3.5Z" fill={color} />
            <path d="M11 10C11 9.72386 11.2239 9.5 11.5 9.5C11.7761 9.5 12 9.72386 12 10V11C12 11.2761 11.7761 11.5 11.5 11.5C11.2239 11.5 11 11.2761 11 11V10Z" fill={color} />
            <path d="M11 13C11 12.7239 11.2239 12.5 11.5 12.5C11.7761 12.5 12 12.7239 12 13V14C12 14.2761 11.7761 14.5 11.5 14.5C11.2239 14.5 11 14.2761 11 14V13Z" fill={color} />
            <path d="M13 10C13 9.72386 13.2239 9.5 13.5 9.5C13.7761 9.5 14 9.72386 14 10V11C14 11.2761 13.7761 11.5 13.5 11.5C13.2239 11.5 13 11.2761 13 11V10Z" fill={color} />
            <path d="M13 13C13 12.7239 13.2239 12.5 13.5 12.5C13.7761 12.5 14 12.7239 14 13V14C14 14.2761 13.7761 14.5 13.5 14.5C13.2239 14.5 13 14.2761 13 14V13Z" fill={color} />
            <path d="M15 10C15 9.72386 15.2239 9.5 15.5 9.5C15.7761 9.5 16 9.72386 16 10V11C16 11.2761 15.7761 11.5 15.5 11.5C15.2239 11.5 15 11.2761 15 11V10Z" fill={color} />
            <path d="M15 13C15 12.7239 15.2239 12.5 15.5 12.5C15.7761 12.5 16 12.7239 16 13V14C16 14.2761 15.7761 14.5 15.5 14.5C15.2239 14.5 15 14.2761 15 14V13Z" fill={color} />
            <path d="M17 10C17 9.72386 17.2239 9.5 17.5 9.5C17.7761 9.5 18 9.72386 18 10V11C18 11.2761 17.7761 11.5 17.5 11.5C17.2239 11.5 17 11.2761 17 11V10Z" fill={color} />
            <path d="M17 13C17 12.7239 17.2239 12.5 17.5 12.5C17.7761 12.5 18 12.7239 18 13V14C18 14.2761 17.7761 14.5 17.5 14.5C17.2239 14.5 17 14.2761 17 14V13Z" fill={color} />
        </svg>
    )
}
