import React, { useState } from 'react'
import { List } from '@phosphor-icons/react'
import { Dropdown } from 'antd'

import centralsIcon from '../../../assets/icons/central.svg'
import reportsIcon from '../../../assets/icons/reports.svg'
import arrowIcon from '../../../assets/icons/small_arrow_rigth.svg'

import { UsersActionModal } from '../../../screens/reportModals/UserActionsModal'
import { ActiveAlertsModal } from '../../../screens/reportModals/ActiveAlertsModal'
import { AlertLogsModal } from '../../../screens/reportModals/AlertLogsModal'
import { BatteryModal } from '../../../screens/reportModals/BatteryModal'
import { AlertHandlingModal } from '../../../screens/reportModals/AlertHandlingModal'

import { displayMessage } from '../../../helpers/messages'
import { colors } from '../../../services/style'

import './menu.css'

export const DropdownMenu = () => {
    const [isUserActionsModalOpen, setIsUserActionsModalOpen] = useState(false)
    const [isActiveAlertsModalOpen, setIsActiveAlertsModalOpen] = useState(false)
    const [isAlertLogsModalOpen, setIsAlertLogsModalOpen] = useState(false)
    const [isBatteryModalOpen, setIsBatteryModalOpen] = useState(false)
    const [isAlertHandlingModalOpen, setIsAlertHandlingModalOpen] = useState(false)

    const reportTypes = {
        USER_ACTIONS: { key: 'user_actions', item_label: displayMessage('USER_ACTIONS') },
        ACTIVE_ALERT: { key: 'active_alert', item_label: displayMessage('ACTIVE_ALERT') },
        ALERT_LOGS: { key: 'alert_logs', item_label: displayMessage('ALERT_LOGS') },
        BATTERY: { key: 'batery_level', item_label: displayMessage('BATTERY') },
        ALERT_HANDLING: { key: 'alert_handling', item_label: displayMessage('ALERT_HANDLING') },
    }

    const menuItems = [
        {
            key: 'change_client',
            image: centralsIcon,
            item_label: displayMessage('CHANGE_CLIENT'),
            children: [],
        },
        {
            key: 'reports',
            image: reportsIcon,
            item_label: displayMessage('REPORTS'),
            theme: 'dark',
            children: Object.values(reportTypes),
        },
    ]
    const items = menuItems.map((item) => ({
        ...item,
        label: (
            <div className="flex items-center justify-between h-[40px]">
                <div className="flex gap-2 items-center">
                    {item.image ? <img src={item.image} className="w-[30px] h-[30px]" /> : item.item_icon}
                    <span className="text-white leading-4">{item.item_label}</span>
                </div>
                <img src={arrowIcon} className="w-[15px] h-[15px]" />
            </div>
        ),
        children: item.children ? item.children.map((children) => ({ ...children, label: <div className="text-white">{children.item_label}</div> })) : null,
    }))

    const handleMenuClick = (e) => {
        switch (e.key) {
            case reportTypes.USER_ACTIONS.key:
                setIsUserActionsModalOpen(true)
                break
            case reportTypes.ACTIVE_ALERT.key:
                setIsActiveAlertsModalOpen(true)
                break
            case reportTypes.ALERT_LOGS.key:
                setIsAlertLogsModalOpen(true)
                break
            case reportTypes.BATTERY.key:
                setIsBatteryModalOpen(true)
                break
            case reportTypes.ALERT_HANDLING.key:
                setIsAlertHandlingModalOpen(true)
                break
        }
    }

    return (
        <>
            {isUserActionsModalOpen && <UsersActionModal onClose={() => setIsUserActionsModalOpen(false)} />}
            {isActiveAlertsModalOpen && <ActiveAlertsModal onClose={() => setIsActiveAlertsModalOpen(false)} />}
            {isAlertLogsModalOpen && <AlertLogsModal onClose={() => setIsAlertLogsModalOpen(false)} />}
            {isBatteryModalOpen && <BatteryModal onClose={() => setIsBatteryModalOpen(false)} />}
            {isAlertHandlingModalOpen && <AlertHandlingModal onClose={() => setIsAlertHandlingModalOpen(false)} />}
            <Dropdown
                menu={{
                    items,
                    style: { backgroundColor: colors['sl-gray'][1000], width: '200px' },
                    expandIcon: <></>,
                    onClick: handleMenuClick,
                }}
                className="rounded my-2"
            >
                <List color="white" className=" cursor-pointer" size={30} />
            </Dropdown>
        </>
    )
}
