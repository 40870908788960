import { ConfigProvider, Popconfirm, Tooltip } from 'antd'

export const SL_Confirm = ({children, tooltipTitle, ...rest}) => {
    return (
        <ConfigProvider
            theme={{
                token: {
                    lineWidth: 0,
                    primaryShadow: '0 0 0',
                },
            }}
        >
            <Popconfirm {...rest}>
                <Tooltip title={tooltipTitle}>
                    {children}
                </Tooltip>
            </Popconfirm>
        </ConfigProvider>
    )
}
