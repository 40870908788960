export const AttachmentSvg = ({ color, size = 24 }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M18.1474 5.2773C16.4877 3.61757 13.7968 3.61757 12.137 5.2773L5.24275 12.1716C4.85223 12.5621 4.21906 12.5621 3.82854 12.1716C3.43801 11.7811 3.43801 11.1479 3.82854 10.7574L10.7228 3.86308C13.1636 1.42231 17.1208 1.42231 19.5616 3.86308C22.0024 6.30389 22.0022 10.2611 19.5615 12.7019L11.6065 20.6568C9.84918 22.4142 7.0002 22.4142 5.24282 20.6569C3.48555 18.8996 3.48516 16.0503 5.2426 14.2929L13.1976 6.33794C14.2715 5.264 16.013 5.264 17.087 6.33794C18.1611 7.41206 18.1605 9.15324 17.0867 10.227L10.1925 17.1213C9.80193 17.5118 9.16877 17.5118 8.77824 17.1213C8.38772 16.7308 8.38772 16.0976 8.77824 15.7071L15.6725 8.81279C15.9656 8.51972 15.9655 8.04487 15.6728 7.75215C15.3799 7.45926 14.9047 7.45926 14.6118 7.75215L6.65681 15.7071C5.68059 16.6833 5.68064 18.2662 6.65704 19.2426C7.63333 20.2189 9.21598 20.2189 10.1923 19.2426L18.1473 11.2876C19.807 9.62788 19.8071 6.93699 18.1474 5.2773Z"
                fill={color}
            />
        </svg>
    )
}
