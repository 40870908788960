export const CheckSvg = ({ color, size = 24 }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M21.1911 5.27726C21.5903 5.65896 21.6044 6.29196 21.2227 6.69112L9.74774 18.6911C9.55893 18.8886 9.29754 19.0002 9.02434 19C8.75114 18.9998 8.4899 18.8879 8.30135 18.6902L2.77635 12.8971C2.39518 12.4974 2.41018 11.8644 2.80984 11.4832C3.2095 11.1021 3.84249 11.1171 4.22366 11.5167L9.02596 16.5521L19.7773 5.30888C20.159 4.90972 20.792 4.89557 21.1911 5.27726Z"
                fill={color}
            />
        </svg>
    )
}
