function Workbook() {
    if (!(this instanceof Workbook)) return new Workbook()

    this.SheetNames = []

    this.Sheets = {}
}

const download = (url, name) => {
    let a = document.createElement('a')
    a.href = url
    a.download = name
    a.click()

    window.URL.revokeObjectURL(url)
}

function s2ab(s) {
    const buf = new ArrayBuffer(s.length)

    const view = new Uint8Array(buf)

    for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff

    return buf
}

export default (data, name = 'import.xlsx') => {
    import('xlsx-js-style').then((XLSX) => {
        const wb = new Workbook()
        const ws = XLSX.utils.json_to_sheet(data)

        const defaultColumnWidth = 20
        const columns = XLSX.utils.sheet_to_json(ws, { header: 1 })[0]
        columns.forEach((col, index) => {
            ws['!cols'] = ws['!cols'] || []
            ws['!cols'][index] = { wch: defaultColumnWidth }
        })

        wb.SheetNames.push('export')
        wb.Sheets['export'] = ws

        const wbout = XLSX.write(wb, {
            bookType: 'xlsx',
            bookSST: true,
            type: 'binary',
        })

        let url = window.URL.createObjectURL(new Blob([s2ab(wbout)], { type: 'application/octet-stream' }))

        download(url, name)
    })
}
