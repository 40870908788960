import { useContext } from 'react'

import centralsIcon from '../../../assets/icons/central.svg'

import { SL_Button } from '../../../components/buttons/Button'

import { displayMessage } from '../../../helpers/messages'
import { GeneralContext } from '../../../context/GeneralContext/GeneralContext'

export const CentralsSection = ({ centrals = [], onManageCentrals }) => {
    const { mobile_mode } = useContext(GeneralContext)

    const getLocation = ({city, state}) => {
        switch (true) {
            case city && state:
                return `${city} - ${state}`
            case !city && !state:
                return displayMessage("NOT_INFORMED_LOCATION")
            default:
                return `${city ?? displayMessage("EMPTY")} - ${state ?? displayMessage("EMPTY")}`
                
        }
    }
    return (
        <div className="mt-5">
            <div className={`flex justify-between mb-2 h-[32px] ${mobile_mode ? 'flex-col' : ''}`}>
                <b1 className="text-white text-[18px]/[24px]"> {displayMessage('CENTRALS')}</b1>
                <SL_Button
                    type="default"
                    className="bg-white/10 hover:bg-white/20 h-[32px] w-[126px] rounded-[8px] py-[10px] px-4 text-white flex justify-center items-center"
                    onClick={() => onManageCentrals()}
                >
                    <span className="text-[14px]/[14px] text-medium">{displayMessage('MANAGE_CENTRALS')}</span>
                </SL_Button>
            </div>

            <div className="flex gap-3 flex-wrap">
                {centrals.map((central) => (
                    <div key={central.id} className={`h-[158px]  rounded-[5px] py-3 pl-3 pr-4 gap-4  bg-sl-transparency-8 flex flex-col ${mobile_mode ? 'w-full justify-center items-center' : 'w-[184px]'}`}>
                        <div className="bg-sl-gray-150 h-[48px] w-[48px] rounded-[10px] flex justify-center items-center">
                            <img src={centralsIcon} width={32} height={32}/>
                        </div>
                        <span className="text-white text-medium text-[14px]/[14px] uppercase">{central.name}</span>
                        <div className='flex flex-col gap-2 text-[11px]/[11px] opacity-40'>
                        <span className="uppercase">{getLocation(central)}</span>
                        <span className="uppercase">{displayMessage(central.devices_count > 1 ? 'DEVICES_COUNT' : 'DEVICES_COUNT_S', [central.devices_count])}</span>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}
