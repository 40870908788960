export const SupervisionSvg = ({ color, size = 24 }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14Z" fill="white" />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M13.7625 2.06354C13.0153 0.645486 10.9847 0.645486 10.2375 2.06354L9.69136 3.1C9.24415 3.94873 8.25557 4.35822 7.33921 4.07429L6.22016 3.72757C4.68911 3.25319 3.25319 4.68911 3.72757 6.22016L4.07429 7.33921C4.35822 8.25557 3.94873 9.24415 3.1 9.69136L2.06354 10.2375C0.645486 10.9847 0.645486 13.0153 2.06354 13.7625L3.1 14.3086C3.94873 14.7558 4.35822 15.7444 4.07429 16.6608L3.72757 17.7798C3.25319 19.3109 4.68911 20.7468 6.22016 20.2724L7.33921 19.9257C8.25557 19.6418 9.24415 20.0513 9.69136 20.9L10.2375 21.9365C10.9847 23.3545 13.0154 23.3545 13.7625 21.9365L14.3086 20.9C14.7558 20.0513 15.7444 19.6418 16.6608 19.9257L17.7798 20.2724C19.3109 20.7468 20.7468 19.3109 20.2724 17.7798L19.9257 16.6608C19.6418 15.7444 20.0513 14.7558 20.9 14.3086L21.9365 13.7625C23.3545 13.0154 23.3545 10.9847 21.9365 10.2375L20.9 9.69136C20.0513 9.24415 19.6418 8.25557 19.9257 7.33921L20.2724 6.22016C20.7468 4.68911 19.3109 3.25319 17.7798 3.72757L16.6608 4.07429C15.7444 4.35822 14.7558 3.94873 14.3086 3.1L13.7625 2.06354ZM12 8C8 8 5.5 12 5.5 12C5.5 12 8 16 12 16C16 16 18.5 12 18.5 12C18.5 12 16 8 12 8Z"
                fill={color}
            />
        </svg>
    )
}
