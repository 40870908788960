import { useContext, useEffect, useState } from 'react'
import { Divider, Form, Spin, Upload } from 'antd'

import { SL_Modal } from '../../../../components/modal/Modal'
import { SL_Input } from '../../../../components/input/Input'
import { GS_Select } from '../../../../components/select/GS_Select'
import { SL_Button } from '../../../../components/buttons/Button'
import { AttachmentSvg } from '../../../../components/icons/AtachmentSVG'
import { CheckSvg } from '../../../../components/icons/CheckSvg'
import { SL_Checkbox } from '../../../../components/checkbox/Checkbox'

import { displayMessage } from '../../../../helpers/messages'
import { getIconByCode } from '../../../../helpers/helper'
import { GeneralContext } from '../../../../context/GeneralContext/GeneralContext'
import { colors } from '../../../../services/style'
import { ECentralAlertTypes } from '../../../../enums/ECentralAlertTypes'
import { EAlertTypes } from '../../../../enums/EAlertTypes'
import api from '../../../../services/api'

export const MonitoringCreationModal = ({ onClose, alert }) => {
    const { windowHeight, windowWidth, mobile_mode, showAlert } = useContext(GeneralContext)
    const modalWidth = 510
    const safeArea = 10
    const positionLeft = windowWidth / 2 - modalWidth / 2 - safeArea

    const [form] = Form.useForm()

    const [emergencyContactsList, setEmergencyContactsList] = useState([])
    const [attachments, setAttachments] = useState([])
    const [selectedEmails, setSelectedEmails] = useState([])

    const [emailView, setEmailView] = useState(false)
    const [showLoading, setShowLoading] = useState(false)

    const [monitoringId, setMonitoringId] = useState(null)
    const [newEmail, setNewEmail] = useState(null)
    const [alertData, setAlertData] = useState(null)

    const handleFileOnChange = ({ fileList: newFileList }) => {
        setAttachments(newFileList)
    }

    const createRegister = async (values) => {
        setShowLoading(true)
        try {
            values.alert_log_id = alert
            const body = new FormData()
            body.append('data', JSON.stringify(values))

            for (let i = 0; i < attachments.length; i++) {
                const file = new File([attachments[i].originFileObj], attachments[i].name, { type: attachments[i].type })
                body.append('attachments', file)
            }

            const { data } = await api.monitorings.create(body)
            setMonitoringId(data.id)
            setEmailView(true)
        } catch (error) {
            console.log(error)
        } finally {
            setShowLoading(false)
        }
    }

    const onAddEmail = (email) => {
        if (!emergencyContactsList.includes(email)) {
            setEmergencyContactsList((current) => [...current, email])
        }
        onChangeEmails(email)
        setNewEmail(null)
    }

    const onChangeEmails = (email) => {
        if (selectedEmails.includes(email)) {
            setSelectedEmails(selectedEmails.filter((selected_email) => selected_email != email))
        } else {
            setSelectedEmails((current) => [...current, email])
        }
    }

    const sendEmails = async () => {
        setShowLoading(true)
        try {
            const body = {
                monitoring_id: monitoringId,
                emails: selectedEmails,
            }

            await api.monitorings.sendEmails(body)
            showAlert('SUCCESS_ON_SEND_EMAILS', EAlertTypes.SUCCESS)
            onClose()
        } catch (error) {
            console.log(error)
        } finally {
            setShowLoading(false)
        }
    }

    const getALertData = async () => {
        setShowLoading(true)
        try {
            const { data } = await api.monitorings.getPrevData(alert)
            setEmergencyContactsList(data.central_emergency_contacts ?? [])
            setAlertData(data)
        } catch (error) {
            console.log(error)
        } finally {
            setShowLoading(false)
        }
    }

    const getData = (code, data) => {
        const alertTypes = {}
        Object.values(ECentralAlertTypes).forEach((cat) => {
            alertTypes[cat.code] = cat
        })

        return alertTypes[code][data]
    }

    const creation_view = (
        <div className="custom-scroll p-4 flex flex-col gap-3" style={{ height: mobile_mode ? windowHeight * 0.8 : attachments.length ? 320 : 304 }}>
            <span className="text-[14px]/[14px]">{displayMessage('ACTIONS_REPORT')}</span>
            <Form onFinish={createRegister} className="w-full flex flex-col gap-2" layout="vertical" requiredMark={false} form={form}>
                <Form.Item name="type" className="mb-0">
                    <GS_Select
                        mode="single"
                        className="w-full font-bold shadow-md bg-sl-transparency-4 text-white rounded-[8px] custom-input"
                        style={{ border: `1px solid ${colors['sl-transparency'][16]}` }}
                        options={Object.values(alertData?.monitoring_types || {})}
                        value={form.getFieldsValue('type')}
                        placeholder={displayMessage('SELECT_ACTIONS')}
                        dropdownStyle={{ backgroundColor: colors['sl-gray'][700], color: 'white' }}
                    />
                </Form.Item>

                <Form.Item name="general_description" className="mb-0">
                    <SL_Input
                        placeholder={displayMessage('WRITE_MORE_DETAILS')}
                        className={'w-full shadow-md bg-sl-transparency-4  text-white custom-input'}
                        textarea={true}
                        maxLength={null}
                        style={{
                            height: 144,
                            resize: 'none',
                        }}
                    />
                </Form.Item>
            </Form>
            <div className=" mb-2">
                <div className="flex w-full gap-2 justify-end">
                    <SL_Button onClick={onClose} type="close" className="bg-white/10 hover:bg-white/20" style={{ zIndex: 1 }}>
                        {displayMessage('CANCEL')}
                    </SL_Button>
                    <SL_Button type="normal" onClick={() => form.submit()} className="bg-primary-500 hover:bg-primary-100" style={{ zIndex: 1 }}>
                        {displayMessage('SAVE')}
                    </SL_Button>
                </div>
                <Upload beforeUpload={() => false} onChange={handleFileOnChange} fileList={attachments} className="w-[90%] absolute top-[250px] custom-scroll" multiple>
                    <AttachmentSvg color={colors['sl-gray'][300]} size={20} className="" />
                </Upload>
            </div>
        </div>
    )

    const emails_view = (
        <div className="custom-scroll pr-2 flex flex-col py-6 px-4 gap-4" style={{ height: mobile_mode ? windowHeight * 0.8 : attachments.length ? 320 : 304 }}>
            <span className="opacity-40 text-[14px]/[14px]">{displayMessage('EMERGENCY_CONTACTS')}</span>
            <div className="custom-scroll pr-2 flex flex-col gap-2  overflow-auto" style={{ height: 320 }}>
                {emergencyContactsList.map((email, i) => (
                    <div key={`email-${i}`} className="flex gap-3 h-[22px] items-center">
                        <SL_Checkbox onChange={() => onChangeEmails(email)} className="rounded-[5px] h-[22px] w-[22px]" checked={selectedEmails.includes(email)} />
                        <span className=" text-[14px]/[14px] text-medium">{email}</span>
                    </div>
                ))}
            </div>
            <SL_Input
                placeholder={displayMessage('ADD_EMAIL')}
                className="w-full font-bold shadow-md bg-sl-transparency-4 text-white rounded-[8px] custom-input-heigth"
                onChange={(e) => setNewEmail(e.target.value)}
                onPressEnter={(e) => onAddEmail(e.target.value)}
                value={newEmail}
            />

            <Divider className="my-0" style={{ borderBottom: `1px solid ${colors['sl-gray'][150]}` }} />

            <div className="flex justify-between items-center">
                <div className="flex gap-2">
                    <CheckSvg color={colors['sl-green'][200]} />
                    <span className="text-sl-green-200">{displayMessage('SAVED_REPORT')}</span>
                </div>
                <div className="flex gap-2 justify-end mb-2">
                    <SL_Button onClick={onClose} type="close" className="bg-white/10 hover:bg-white/20" style={{ zIndex: 1 }}>
                        {displayMessage('DONT_SEND')}
                    </SL_Button>
                    <SL_Button type="normal" onClick={() => sendEmails()} className="bg-primary-500 hover:bg-primary-100" style={{ zIndex: 1 }}>
                        {displayMessage('SEND_EMAILS')}
                    </SL_Button>
                </div>
            </div>
        </div>
    )

    const title = (
        <>
            {emailView ? (
                <div className="p-4 h-[48px]">
                    <span className="text-[16px]/[16px]">{displayMessage('SEND_REPORT_TO_EMAIL')}</span>
                </div>
            ) : (
                <>
                    {alertData && (
                        <div className="h-[72px] flex justify-between items-center p-4">
                            <div className="flex gap-2 items-center text-sm">
                                <div className="rounded-[120px] p-2 bg-sl-gray-100 flex justify-center items-center h-[40px] w-[40px]" style={{ border: `solid 1px ${colors['sl-gray'][150]}` }}>
                                    {getIconByCode(alertData.type, alertData.color)}
                                </div>
                                <div className="flex flex-col">
                                    <span style={{ color: alertData?.color }} className="text-[11px]/[11px]">
                                        {getData(alertData?.type, 'label')}
                                    </span>
                                    <span className="uppercase text-[14px]/[14px]">{alertData?.label}</span>
                                </div>
                            </div>

                            <div className="flex flex-col items-end opacity-40">
                                <span className=" text-[11px]/[11px]">{alertData?.date}</span>
                                <span className=" text-[11px]/[11px]">{alertData?.minutes}</span>
                            </div>
                        </div>
                    )}
                </>
            )}

            <Divider className="my-0" style={{ borderBottom: `1px solid #2D2D2F` }} />
        </>
    )

    useEffect(() => {
        getALertData()
    }, [])

    return (
        <SL_Modal
            open={true}
            closable={false}
            closeIcon={null}
            footer={null}
            width={modalWidth}
            centered={false}
            title={title}
            onCancel={onClose}
            mask={false}
            baseColor={colors['sl-gray'][1000]}
            style={
                mobile_mode
                    ? {}
                    : {
                          top: 55,
                          left: positionLeft,
                      }
            }
            bodyStyle={{
                padding: 12,
            }}
        >
            {/* <Divider className="mb-4 mt-0" style={{ borderBottom: `1px solid ${colors['sl-gray'][150]}` }} /> */}
            <Spin spinning={showLoading} delay={500}>
                {emailView ? emails_view : creation_view}
            </Spin>
        </SL_Modal>
    )
}
