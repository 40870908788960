import { MagnifyingGlass } from "@phosphor-icons/react"
import { SL_Input } from "../../../components/input/Input" 
import { displayMessage } from "../../../helpers/messages"

export const Search = ({ text, setText, placeholder }) => {
    return (
        <div className="flex flex-col gap-2">
            <span className="text-[18px]/[18px]">{displayMessage('SEARCH')}</span>
            <SL_Input
                placeholder={placeholder} 
                className={'w-full font-bold shadow-md bg-black text-white search-input custom-input-height'}
                value={text}
                onChange={(e) => setText(e.target.value)}
                prefix={<MagnifyingGlass size={16} />}
                allowClear={true}
            />
        </div>
    )
}
