import { Spin } from 'antd'
import { useState } from 'react'
import { SL_Button } from '../../../components/buttons/Button'
import { SL_Input } from '../../../components/input/Input'
import { displayMessage } from '../../../helpers/messages'
import { colors } from '../../../services/style'
import { SL_Modal } from '../../../components/modal/Modal'
import { MailOpenOutline } from 'react-ionicons'

export function ForgotPasswordModal({ emailSended, onOk, onClose, showLoading, showSuccessInfoInForgotPasswordModal }) {
    const [email, setEmail] = useState('')

    return (
        <>
            <SL_Modal
                title={<div style={{ color: colors['sl-gray'][50] }}>{displayMessage('CHANGE_PASSWORD')}</div>}
                open={true}
                onCancel={onClose}
                centered={true}
                footer={
                    !showLoading && (
                        <div className="flex flex-row gap-2 justify-end">
                            <SL_Button type="primary" className={'w-full font-bold shadow-md bg-sl-red-200'} htmlType="submit" onClick={() => onClose()}>
                                {displayMessage('GO_BACK')}
                            </SL_Button>

                            {!showSuccessInfoInForgotPasswordModal && (
                                <SL_Button
                                    type="primary"
                                    htmlType="submit"
                                    className={'w-full font-bold shadow-md bg-primary-500'}
                                    onClick={() => {
                                        onOk(email)
                                    }}
                                >
                                    {emailSended ? displayMessage('RESEND') : displayMessage('SEND')}
                                </SL_Button>
                            )}
                        </div>
                    )
                }
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        minHeight: showLoading ? 114 : 70,
                    }}
                >
                    {showLoading ? (
                        <div>
                            <Spin tip="Aguarde..." />
                        </div>
                    ) : showSuccessInfoInForgotPasswordModal ? (
                        <div style={{ textAlign: 'center' }}>{displayMessage('RESET_PASSWORD_SOLICITATION_SUCCESSFULLY')}</div>
                    ) : (
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                flex: 1,
                                color: colors['sl-gray'][50],
                            }}
                        >
                            <div style={{ marginBottom: 10 }}>{displayMessage('INFO_FORGOT_PASSWORD')}</div>

                            <SL_Input
                                suffix={<MailOpenOutline color={'#ffffff'} style={{ marginTop: 5 }} />}
                                type={'email'}
                                value={email}
                                required={true}
                                placeholder={displayMessage('EMAIL')}
                                onChange={(e) => {
                                    setEmail(e.target.value)
                                }}
                            />
                        </div>
                    )}
                </div>
            </SL_Modal>
        </>
    )
}
