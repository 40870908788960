import { useContext, useEffect, useState } from 'react'
import { Form } from 'antd'

import centralIcon from '../../../assets/icons/central.svg'

import { Search } from '../components/Search'
import { NewOption } from '../components/NewOption'
import { Option } from '../components/Option'
import { NewCentral } from './components/NewCentral'
import { NewTitle } from '../components/NewTitle'

import { displayMessage } from '../../../helpers/messages'
import { GeneralContext } from '../../../context/GeneralContext/GeneralContext'
import { EAlertTypes } from '../../../enums/EAlertTypes'

import api from '../../../services/api'
import '../gradients.css'

export const CentralsOption = ({ onChangeTitle, setShowLoading }) => {
    const [form] = Form.useForm()
    const { showAlert } = useContext(GeneralContext)
    const [isAdding, setIsAdding] = useState(false)
    const [isEditing, setIsEditing] = useState(false)
    const [isLooking, setIsLooking] = useState(false)

    const [centrals, setCentrals] = useState(null)
    const [central, setCentral] = useState(null)
    const [showedCentrals, setShowedCentrals] = useState([])

    const [search, setSearch] = useState('')

    const actions = {
        ADD: {
            key: 1,
            label: displayMessage('ADD_CENTRAL'),
        },
        EDIT: {
            key: 2,
            label: displayMessage('EDIT_CENTRAL'),
        },
        REMOVE: {
            key: 3,
            label: displayMessage('REMOVE_CENTRAL'),
        },
        SEE: {
            key: 4,
        },
    }

    const backToInitState = () => {
        setIsAdding(false)
        setIsEditing(false)
        setIsLooking(false)
        setCentral(null)
        onChangeTitle(null)
        form.resetFields()
    }

    const handleOnClickAction = (key, central) => {
        console.log(central)
        if (central) {
            setCentral(central)
            form.setFieldsValue(central)
        }
        let newTitle
        let showButtons = true
        switch (key) {
            case actions.ADD.key:
                newTitle = displayMessage('ADD_CENTRAL')
                setIsAdding(true)
                break
            case actions.EDIT.key:
                newTitle = displayMessage('EDIT_CENTRAL')

                setIsEditing(true)
                break
            case actions.SEE.key:
                newTitle = central.name
                showButtons = false

                setIsLooking(true)
                break
        }
        onChangeTitle(<NewTitle title={newTitle} showButtons={showButtons} back={() => backToInitState()} form={form}/>)
    }

    const createCentral = async (values) => {
        setShowLoading(true)
        try {
            await api.centrals.create(values)
            showAlert(displayMessage('SUCCESS_CREATE_CENTRAL'), EAlertTypes.SUCCESS)
            backToInitState()
            getCentrals()
        } catch (error) {
            console.log(error)
        } finally {
            setShowLoading(false)
        }
    }

    const editCentral = async (values) => {
        setShowLoading(true)
        try {
            await api.centrals.update(central.id, values)
            showAlert(displayMessage('SUCCESS_EDIT_CENTRAL'), EAlertTypes.SUCCESS)
            backToInitState()
            getCentrals()
        } catch (error) {
            console.log(error)
        } finally {
            setShowLoading(false)
        }
    }

    const deleteCentral = async (id) => {
        setShowLoading(true)
        try {
            await api.centrals.delete(id)
            showAlert(displayMessage('SUCCESS_DELETE_CENTRAL'), EAlertTypes.SUCCESS)
            getCentrals()
        } catch (error) {
            console.log(error)
        } finally {
            setShowLoading(false)
        }
    }

    const getCentrals = async () => {
        setShowLoading(true)
        try {
            const { data } = await api.centrals.list()
            setCentrals(data)
            setShowedCentrals(data)
        } catch (error) {
            console.log(error)
        } finally {
            setShowLoading(false)
        }
    }

    const handleNewUser = async (user) => {
        setShowLoading(true)
        try {
            const body = {
                central_id: central.id,
                user_id: user.value,
            }
            const { data } = await api.userCentral.vinculate(body)
            showAlert(displayMessage('SUCCESS_ADD_ACESS_FROM_USER_TO_CENTRAL'), EAlertTypes.SUCCESS)

            setCentrals([])

            const newCentrals = [...centrals]
            const newCentral = { ...central }
            const idx = newCentrals.findIndex((c) => c.id == central.id)
            newCentrals[idx].Users = data
            newCentral.Users = data

            setCentrals(newCentrals)
        } catch (error) {
            console.log(error)
        } finally {
            setShowLoading(false)
        }
    }

    const handleDeleteUser = async (user_id) => {
        setShowLoading(true)
        try {
            const { data } = await api.userCentral.delete(user_id, central.id)
            showAlert(displayMessage('SUCCESS_DELETE_ACESS_FROM_USER_TO_CENTRAL'), EAlertTypes.SUCCESS)
            const newCentrals = [...centrals]
            const newCentral = { ...central }
            const idx = newCentrals.findIndex((c) => c.id == central.id)
            newCentrals[idx].Users = data
            newCentral.Users = data

            setCentrals(newCentrals)
        } catch (error) {
            console.log(error)
        } finally {
            setShowLoading(false)
        }
    }

    useEffect(() => {
        getCentrals()
    }, [])

    useEffect(() => {
        if (!search || search == '') {
            setShowedCentrals(centrals)
        } else {
            const newShowedCentrals = [...centrals].filter((central) => {
                const { id, name } = central
                return `${id}`.includes(search) || name.toLowerCase().includes(search.toLowerCase())
            })

            setShowedCentrals(newShowedCentrals)
        }
    }, [search])

    return (
        <>
            {!isAdding && !isEditing && !isLooking && (
                <div className="flex flex-col gap-2">
                    <NewOption onClickAction={() => handleOnClickAction(actions.ADD.key)} message={displayMessage('CLICK_TO_ADD_A_NEW_CENTRAL')} />
                    <Search placeholder={displayMessage('TO_SEARCH_CENTRALS')} setText={setSearch} text={search} />

                    {centrals &&
                        showedCentrals.map((central) => (
                            <Option
                                key={central.key}
                                iconImg={centralIcon}
                                onClickTitle={() => handleOnClickAction(actions.SEE.key, central)}
                                title={central.name}
                                onClickEdition={() => handleOnClickAction(actions.EDIT.key, central)}
                                onConfirmDelete={() => deleteCentral(central.id)}
                                deleteMessage={displayMessage('DELETE_CENTRAL_CONFIRM_MESSAGE')}
                                editTooltip={actions.EDIT.label}
                                removeTooltip={actions.REMOVE.label}
                            />
                        ))}
                </div>
            )}

            {isAdding && <NewCentral sendValues={createCentral} form={form} />}
            {isEditing && <NewCentral sendValues={editCentral} form={form} alowVinculateUsers={true} users={central?.Users} handleNewUser={handleNewUser} handleDeleteUser={handleDeleteUser} />}
            {isLooking && <NewCentral form={form} onlyView={true} />}
        </>
    )
}
