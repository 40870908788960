import { useContext, useEffect, useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { AuthContext } from '../../context/AuthContext/AuthContext'
import { SplashScreen } from './SplashScreen'
import api from '../../services/api'

// Descomentar esse componente e comentar a PrivateRoute abaixo caso queira não queria autentificação de usuários no sistema nesse momento
// export const PrivateRoute = ({ redirectPath = '/login' }) => {
//     return <Outlet />
// }

export const PrivateRoute = ({ redirectPath = '/login' }) => {
    const [isLoadingSplash, setIsLoadingSplash] = useState(true)
    const navigate = useNavigate()

    const { onChangeIsLogged, setAuthorizationToken, setWebSocketToken, getOrCreateWebSocketToken, getAuthorizationToken, onChangeCurrentUser } = useContext(AuthContext)

    useEffect(() => {
        setTimeout(() => {
            validateLoggedUser()
        }, 500)
    }, [])

    async function validateLoggedUser() {
        try {
            const token = getAuthorizationToken()
            if (!token) {
                throw new Error()
            }

            const websocketToken = getOrCreateWebSocketToken()

            setAuthorizationToken(token)
            setWebSocketToken(websocketToken)

            const { data: user } = await api.auth.currentUser()
            if (!user) {
                throw new Error()
            }

            onChangeCurrentUser(user)
            setIsLoadingSplash(false)
        } catch (error) {
            onChangeIsLogged(false)
            navigate(redirectPath, { replace: true })
        }
    }

    if (isLoadingSplash) {
        return <SplashScreen />
    }

    return <Outlet />
}
