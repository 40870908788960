import { displayMessage } from '../helpers/messages'

export const ETimes = {
    TODAY: {
        value: 1,
        label: displayMessage('TODAY'),
    },
    LAST_WEEK: {
        value: 7,
        label: displayMessage('LAST_WEEK'),
    },
    LAST_TWO_WEEKS: {
        value: 15,
        label: displayMessage('LAST_TWO_WEEKS'),
    },
    LAST_MONTH: {
        value: 30,
        label: displayMessage('LAST_MONTH'),
    },
}
