import { useContext } from 'react'
import { Tooltip } from 'antd'

import trashIcon from '../../../assets/icons/trash.svg'
import pencilIcon from '../../../assets/icons/pencil.svg'

import { SL_Confirm } from '../../../components/confirm/Confirm'

import { GeneralContext } from '../../../context/GeneralContext/GeneralContext'

export const Option = ({ iconImg, icon, onClickTitle, title, onClickEdition, onConfirmDelete, deleteMessage, editTooltip, removeTooltip }) => {
    const { mobile_mode } = useContext(GeneralContext)
    return (
        <div className={`w-full h-[60px] flex items-center p-3 gradient-button radius-[8px] flex-wrap ${mobile_mode ? 'gap-1 justify-center' : ' justify-between'}`}>
            <div className="flex cursor-pointer items-center gap-[15px]" onClick={() => onClickTitle()}>
                {iconImg && <img src={iconImg} height={24} width={24} />}
                {icon && icon}

                <span className="text-white text-[14px]/[14px] font-medium">{title}</span>
            </div>
            <div className="flex gap-[15px]">
                <Tooltip title={editTooltip}>
                    <div className="h-[35px] w-[35px] bg-sl-gray-100 flex justify-center items-center cursor-pointer" onClick={() => onClickEdition()}>
                        <img src={pencilIcon} height={24} width={24} />
                    </div>
                </Tooltip>
                <SL_Confirm title={deleteMessage} onConfirm={() => onConfirmDelete()} okText={removeTooltip} placement="bottom" tooltipTitle={removeTooltip}>
                    <div className="h-[35px] w-[35px] bg-sl-gray-100 flex justify-center items-center cursor-pointer">
                        <img src={trashIcon} height={24} width={24} />
                    </div>
                </SL_Confirm>
            </div>
        </div>
    )
}
