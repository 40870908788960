import { Switch } from 'antd'
import { colors } from '../../../../services/style'

export const Option = ({ title, description, onChange, checked }) => {
    return <div className="rounded-[8px] p-4 gap-4 flex justify-between bg-sl-transparency-16 items-center" style={{ border: `1.5px solid ${colors['sl-transparency'][8]}` }}>
        <div className='flex flex-col gap-1'>
            <span className='text-[18px]/[25.2px]'>{title}</span>
            <span className='text-[14px]/[19.6px]'>{description}</span>
        </div>
        <Switch onChange={onChange} className='w-[50px] rounded-[100px]' defaultChecked={checked}/>
    </div>
}
