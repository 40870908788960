import { useEffect, useRef, useState } from 'react'

import atentionIcon from '../../../assets/icons/atention.svg'

import { getColorByLuminosity, getIcon } from '../../../helpers/helper'
import { displayMessage } from '../../../helpers/messages'
import { ECentralAlertTypes } from '../../../enums/ECentralAlertTypes'
export const Central = ({ data, onOpenCentral, onChangeVisibility, index, fromGroup = null, littleCentral = false }) => {
    const alertTypes = {}
    Object.values(ECentralAlertTypes).forEach((cat) => {
        alertTypes[cat.type] = cat
    })

    const [isHovered, setIsHovered] = useState(false)

    const handleIntersection = (entry) => {
        if (fromGroup || littleCentral) return
        if (![null, undefined].includes(data.priority)) {
            onChangeVisibility((current) => ({ ...current, [data.central_id]: { isVisible: entry.isIntersecting, ...data } }))
        }
    }

    const elementRef = useIntersectionObserver(handleIntersection, {
        root: null,
        rootMargin: '0px',
        threshold: 0.1,
    })

    return (
        <div className="relative">
            {data.offline && !isHovered && (
                <div className={`absolute flex justify-center items-center font-bold ${littleCentral ? 'w-[67.54px] h-[69.36px] gap-0.5' : 'w-[253.71px] h-[256px]  gap-2 '}`}>
                    <img src={atentionIcon} height={littleCentral ? 5 : 24} />
                    <span className={`${littleCentral ? 'text-[5.42px]/[5.42px] ' : 'text-[20px]/[20px]'}`}>{displayMessage('OFFLINE')}</span>
                </div>
            )}
            <div
                id={index == 0 ? `${littleCentral ? 'little-central-' : ''}first_central` : `${littleCentral ? 'little-central-' : ''}central-${index}`}
                className={` bg-sl-gray-100  gradient-button cursor-pointer hover:opacity-100 ${data.offline ? 'opacity-15' : 'opacity-100'} ${
                    littleCentral ? 'h-[69.36px] w-[67.54px] rounded' : 'w-[253.71px] h-[256px] rounded-2xl'
                }`}
                onClick={() => onOpenCentral(data.central_id)}
                ref={fromGroup ? null : elementRef}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            >
                <div
                    style={{ backgroundColor: data.color, color: getColorByLuminosity(data.color) }}
                    className={`${littleCentral ? 'rounded-t text-[5.42px]/[5.42px] h-[13.67px]' : 'rounded-t-2xl p-4 text-[20px]/[20px]'} w-full flex items-center justify-center `}
                >
                    <span className="truncate font-medium">{data.central_name}</span>
                </div>

                <div className={`grid grid-cols-2 ${littleCentral ? 'w-[67.54px] h-[55.69px]' : 'h-[204px] w-[253.71px]'}`}>
                    {Object.values(data?.alerts)
                        .filter((alert) => alert.monitoring)
                        .map((alert) => (
                            <div className={`flex flex-col items-center justify-center ${littleCentral ? ' w-[33.77px] h-[27.84px] gap-[2.71px]' : 'gap-[10px] w-[126.86px] h-[102px]'}`}>
                                <div className={`flex justify-center items-center ${littleCentral ? 'gap-[1px]' : 'gap-[5px] h-[32px] w-[71px]'}`}>
                                    {getIcon(alert.type, alert.color, littleCentral ? 8.67 : 32)}
                                    <div
                                        style={{ backgroundColor: alert.color, color: getColorByLuminosity(alert.color) }}
                                        className={`${
                                            littleCentral ? 'text-[3px]/[2px] px-[3.25px] py-[1.08px] rounded-[270.92px] w-[9.5px] h-[6.17px]' : ' text-xs px-3 py-1 w-[34px] h-[22px] rounded-[1000px]'
                                        } flex items-center justify-center`}
                                    >
                                        <span className={`${littleCentral ? 'text-[3.79px]/[3.79px] ' : 'text-[14px]/[14px]'} font-medium`}>{alert.quantity}</span>
                                    </div>
                                </div>
                                <div className="flex justify-center items-center">
                                    <span className={`${littleCentral ? 'text-[2.98px]/[2.98px] ' : 'text-[11px]/[11px]'} uppercase text-medium`} style={{ fontSize: 10 }}>
                                        {alert.small_label ?? alert.label}
                                    </span>
                                </div>
                            </div>
                        ))}
                </div>
            </div>
        </div>
    )
}

const useIntersectionObserver = (callback, options) => {
    const elementRef = useRef(null)

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                callback(entry)
            })
        }, options)

        if (elementRef.current) {
            observer.observe(elementRef.current)
        }

        return () => {
            if (elementRef.current) {
                observer.unobserve(elementRef.current)
            }
        }
    }, [callback, options])

    return elementRef
}
