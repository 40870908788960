import { useContext, useEffect, useState } from 'react'
import { Form, Tag } from 'antd'

import centralsIcon from '../../../assets/icons/central.svg'

import { Search } from './Search'
import { SL_Checkbox } from '../../../components/checkbox/Checkbox'

import { GeneralContext } from '../../../context/GeneralContext/GeneralContext'
import { displayMessage } from '../../../helpers/messages'
import { hexToRgba } from '../../../helpers/helper'
import { colors } from '../../../services/style'

export const ManageCentrals = ({ data, form, onManageCentrals, isAdding = false }) => {
    const { mobile_mode } = useContext(GeneralContext)
    const [showedCentrals, setShowedCentrals] = useState(data.centrals)
    const [checkedCentrals, setCheckedCentrals] = useState(data.centrals.filter((central) => central.checked).map((central) => central.id))
    const [text, setText] = useState(null)

    const doActionWithAllVisible = (checked_value) => {
        const ids = []
        showedCentrals.forEach((central) => {
            ids.push(central.id)
            form.setFieldValue(central.id, checked_value)
        })

        handleChanges(checked_value, ids)
        setText('')
    }

    const handleChanges = (checked_value, ids) => {
        let newCentrals
        if (checked_value) {
            newCentrals = checkedCentrals.concat(ids)
        } else {
            newCentrals = checkedCentrals.filter((central) => !ids.includes(central))
        }
        setCheckedCentrals([...new Set(newCentrals)])
    }

    const onChangeCheckbox = (central_id, checked_value) => {
        form.setFieldValue(central_id, checked_value)
        handleChanges(checked_value, [central_id])
    }

    const getLocation = ({ city, state }) => {
        switch (true) {
            case city && state:
                return `${city} - ${state}`
            case !city && !state:
                return displayMessage('NOT_INFORMED_LOCATION')
            default:
                return `${city ?? displayMessage('EMPTY')} - ${state ?? displayMessage('EMPTY')}`
        }
    }

    useEffect(() => {
        form.setFieldsValue(data.data_to_form)

    }, [data])

    useEffect(() => {
        if (!text || text == '') {
            setShowedCentrals(data.centrals)
        } else {
            const newShowedCentrals = [...showedCentrals].filter((central) => {
                const { id, name } = central
                return `${id}`.includes(text) || name.toLowerCase().includes(text.toLowerCase())
            })

            setShowedCentrals(newShowedCentrals)
        }
    }, [text])

    return (
        <div className={`flex flex-col gap-[40px] ${isAdding ? '' : 'p-6'}`}>
            {!isAdding && <Search placeholder={displayMessage('TO_SEARCH_CENTRALS')} setText={setText} text={text} />}
            <div className="flex flex-col gap-6">
                <div className="flex gap-2">
                    <span className="text-[18px]/[18px]">{displayMessage(isAdding ? 'PERMISSIONS' : 'RESULTS')}</span>
                    <Tag
                        color={hexToRgba(colors.primary[600], 0.2)}
                        className="text-primary-600 cursor-pointer h-[20px] w-[98px] rounded-[5px] py-[2px] px-[5px] flex items-center"
                        onClick={() => doActionWithAllVisible(true)}
                    >
                        <span className="text-[12px]/[16px]">{displayMessage('ADD_ALL')}</span>
                    </Tag>
                    <Tag
                        color={hexToRgba(colors['sl-red'][200], 0.2)}
                        className="text-sl-red-200 cursor-pointer h-[20px] w-[98px] rounded-[5px] py-[2px] px-[5px] flex items-center"
                        onClick={() => doActionWithAllVisible(false)}
                    >
                        <span className="text-[12px]/[16px]">{displayMessage('REMOVE_ALL')}</span>
                    </Tag>
                </div>
                <Form onFinish={onManageCentrals} className="flex gap-4 flex-wrap" layout="vertical" requiredMark={false} form={form}>
                    {showedCentrals.map((central) => (
                        <div
                            key={central.id}
                            className={`h-[158px] relative rounded-[5px] py-3 pl-3 pr-4 gap-4  bg-sl-transparency-8 flex flex-col ${mobile_mode ? 'w-full justify-center items-center' : 'w-[184px]'}`}
                        >
                            <Form.Item name={central.id} className="m-0 absolute top-2 right-2">
                                <SL_Checkbox className="absolute top-0.5 right-1 manage-centrals-checkbox" checked={checkedCentrals.includes(central.id)} onChange={(e) => onChangeCheckbox(central.id, e.target.checked)} />
                            </Form.Item>
                            <div className="bg-sl-gray-150 h-[48px] w-[48px] rounded-[10px] flex justify-center items-center">
                                <img src={centralsIcon} width={32} height={32} />
                            </div>
                            <span className="text-white text-medium text-[14px]/[14px] uppercase">{central.name}</span>
                            <div className="flex flex-col gap-2 text-[11px]/[11px] opacity-40">
                                <span className="uppercase">{getLocation(central)}</span>
                                <span className="uppercase">{displayMessage(central.devices_count > 1 ? 'DEVICES_COUNT' : 'DEVICES_COUNT_S', [central.devices_count])}</span>
                            </div>
                        </div>
                    ))}
                </Form>
            </div>
        </div>
    )
}
