import { useEffect, useState } from 'react'
import { axiosInstance } from '../../services/axiosInstance'
import { AuthContext } from './AuthContext'
import api from '../../services/api'
// import EUserTypes from '../../enums/EUserTypes'

export function AuthContextProvider({ children }) {
    const [currentUser, setCurrentUser] = useState(null)
    const [isLogged, setIsLogged] = useState(false)
    const [userPermissions, setUserPermissions] = useState([])
    const [isAdmin, setIsAdmin] = useState(true)

    useEffect(() => {
        // if (loggedUser?.type == EUserTypes.ADMIN.code) {
        //     setIsAdmin(true)
        //     return
        // }

        setIsAdmin(false)
    }, [currentUser])

    function onChangeCurrentUser(newUserData) {
        setCurrentUser(newUserData)
    }

    async function onChangeIsLogged(login, body) {
        try {
            if (login) {
                const { data } = await api.auth.login(body)
                if (!data?.token) {
                    throw new Error('"api.auth.login" não retornou o token da requisicao')
                }

                setAuthorizationToken('Bearer ' + data?.token)
                setWebSocketToken(data?.websocket_token)
                setCurrentUser(data?.user)
                setIsLogged(true)
                return true
            } else {
                const token = localStorage.getItem('websocket_token')
                await api.auth.removeSocketConection(token)
                removeAuthorizationToken()

                // Tratativa de redirect feita dentro do GeneralRoute
                // const refirectRoute = window.location.origin + '/login'
                // window.location.href = refirectRoute

                setTimeout(() => {
                    setCurrentUser(null)
                    setIsLogged(false)
                }, 2000)

                return
            }
        } catch (error) {
            console.log(error)
        }
    }

    function setWebSocketToken(token) {
        localStorage.setItem('websocket_token', token)
    }

    function getOrCreateWebSocketToken() {
        const token = localStorage.getItem('websocket_token')
        if (!token) {
            const newToken = Math.random()
            setWebSocketToken(Math.random())
            return newToken
        }

        return token
    }

    function setAuthorizationToken(token, mustFormatToken = false) {
        let formatedToken = token

        if (mustFormatToken) {
            formatedToken = 'Bearer ' + token
        }

        axiosInstance.defaults.headers.common.Authorization = formatedToken
        localStorage.setItem('token', formatedToken)
    }

    function getAuthorizationToken() {
        return localStorage.getItem('token')
    }

    function removeAuthorizationToken() {
        axiosInstance.defaults.headers.common.Authorization = null

        localStorage.removeItem('user')
        localStorage.removeItem('token')
        localStorage.removeItem('websocket_token')
    }

    return (
        <AuthContext.Provider
            value={{
                isLogged,
                currentUser,
                setCurrentUser: onChangeIsLogged,
                onChangeIsLogged,
                onChangeCurrentUser,
                userPermissions,
                setUserPermissions,
                isAdmin,
                setAuthorizationToken,
                getAuthorizationToken,
                removeAuthorizationToken,
                setWebSocketToken,
                getOrCreateWebSocketToken,
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}
