import { GS_Select } from './GS_Select'
import { Spin } from 'antd'
import { useRef, useState } from 'react'

export function MultiSelect({
    fetchOptions,
    debounceTimeout = 250,
    charactersLimitToFetch = 3,
    value,
    onChange,
    placeholder,
    onSearchClick = false,
    mode = 'multiple',
    maxTagCount = 'responsive',
    multipleModeStyle,
    withoutCaracteresTratative,
    onChangeSearchValue,
    ...props
}) {
    const [fetching, setFetching] = useState(false)
    const [options, setOptions] = useState([])
    const fetchRef = useRef(0) //Tratativa pega da doc do Antd

    function cleanOptions() {
        if (options?.length != 0) {
            setOptions([])
        }
    }

    const onSearch = (value) => {
        cleanOptions()
        onChangeSearchValue && onChangeSearchValue(value)
        let valueWithoutSymbol = value
        if (!value) {
            return
        }
        if (typeof value == 'string' && !withoutCaracteresTratative) {
            valueWithoutSymbol = value?.replace(/[^\wáàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ;]/g, '')
            if (valueWithoutSymbol.length < charactersLimitToFetch) {
                fetchRef.current = 0
                return
            }
        }
        setFetching(true)
        fetchRef.current += 1
        const fetchId = fetchRef.current

        setTimeout(async () => {
            try {
                const newOptions = await fetchOptions(valueWithoutSymbol)
                if (fetchId !== fetchRef.current) {
                    return
                }
                setOptions(newOptions)
            } finally {
                setFetching(false)
            }
        }, debounceTimeout)
    }

    return (
        <div className="flex flex-1">
            {mode == 'single' ? (
                <GS_Select
                    labelInValue
                    filterOption={false}
                    onClick={onSearchClick ? onSearch : null}
                    onSearch={onSearch}
                    notFoundContent={fetching ? <Spin size="small" /> : null}
                    options={options}
                    onBlur={() => {
                        cleanOptions()
                    }}
                    // style={{ width: '100%' }}
                    value={value}
                    onChange={onChange}
                    placeholder={placeholder}
                    showSearch={true}
                    {...props}
                />
            ) : (
                <GS_Select
                    labelInValue
                    filterOption={false}
                    onClick={onSearchClick ? onSearch : null}
                    onSearch={onSearch}
                    notFoundContent={fetching ? <Spin size="small" /> : null}
                    options={options}
                    maxTagCount={maxTagCount}
                    onBlur={() => {
                        cleanOptions()
                    }}
                    style={multipleModeStyle ? multipleModeStyle : { width: '100px' }}
                    value={value}
                    onChange={onChange}
                    placeholder={placeholder}
                    mode={'multiple'}
                    {...props}
                />
            )}
        </div>
    )
}

//EXEMPLO DE USO
// const Test = () => {
//     const [value, setValue] = useState([])

//     async function fetchUserList(value) {
//         const { data } = await axios.get('https://randomuser.me/api/?results=5')

//         return data.results.map(user => ({
//             label: `${user.name.first} ${user.name.last}`,
//             value: user.login.username,
//         }))
//     }

//     return (
//         <MultiSelect
//             value={value}
//             onChange={newValue => {
//                 setValue(newValue)
//             }}
//             placeholder="Select users"
//             fetchOptions={fetchUserList}
//         />
//     )
// }
