import { useContext } from 'react'
import plusIcon from '../../../assets/icons/plus.svg'

import { GeneralContext } from '../../../context/GeneralContext/GeneralContext'
import { colors } from '../../../services/style'

export const NewOption = ({ onClickAction, message }) => {
    const { mobile_mode } = useContext(GeneralContext)
    return (
        <div className="h-[60px] w-full flex justify-center items-center gap-3 cursor-pointer p-3 radius-[8px]" style={{ border: `dashed 2px ${colors['sl-gray'][300]}` }} onClick={() => onClickAction()}>
            <span className="text-white text-[14px]/[14px] font-medium	">{message}</span>
            {!mobile_mode && (
                <div className="h-[35px] w-[35px] bg-sl-gray-100 flex justify-center items-center">
                    <img src={plusIcon} height={24} width={24}/>
                </div>
            )}
        </div>
    )
}
