export const FireSvg = ({ color, size = 24 }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M15.4399 21.8896C15.8738 20.6573 16.1445 18.7414 14.9144 17.571C14.8045 18.1202 14.3042 19.2658 13.1828 19.4541C13.8012 17.8456 14.1311 14.1657 10.5029 12.314C10.764 13.1378 10.8574 15.1465 9.1423 16.5902C7.57485 17.9096 8.03489 20.3823 8.81454 22C5.98133 20.8274 4 18.1384 4 15.0086C4 14.4835 4.05578 13.9707 4.16202 13.4755C4.80531 10.3928 6.59457 8.3151 7.44102 7.49896C6.56071 9.36138 7.07422 11.7536 7.44102 12.7169C7.28583 11.9409 7.43256 9.95543 9.26088 8.22145C11.0892 6.48747 10.4459 3.35133 9.89571 2C15.1775 4.63308 16.103 9.14463 15.9055 11.0713C17.0566 10.4612 17.0623 8.91718 16.9212 8.22145C17.288 8.70311 18.2416 10.1113 19.122 11.5242C19.6868 12.5684 20 13.7531 20 15.0086C20 18.0438 18.1366 20.6645 15.4399 21.8896Z"
                fill={color}
            />
        </svg>
    )
}
