import { useContext, useEffect, useState } from 'react'
import { Form } from 'antd'

import buildingsIcon from '../../../assets/icons/buildings.svg'

import { NewOption } from '../components/NewOption'
import { Option } from '../components/Option'
import { NewCompany } from './components/NewCompany'
import { NewTitle } from '../components/NewTitle'
import { ManageCentrals } from '../components/ManageCentrals'
import { CentralsSection } from '../components/CentralsSection'

import { displayMessage } from '../../../helpers/messages'
import { GeneralContext } from '../../../context/GeneralContext/GeneralContext'
import { EAlertTypes } from '../../../enums/EAlertTypes'

import api from '../../../services/api'
import '../gradients.css'

export const CompaniesOption = ({ onChangeTitle, setShowLoading }) => {
    const [form] = Form.useForm()
    const { showAlert, mobile_mode } = useContext(GeneralContext)
    const [isAdding, setIsAdding] = useState(false)
    const [isEditing, setIsEditing] = useState(false)
    const [isLooking, setIsLooking] = useState(false)
    const [isManaging, setIsManaging] = useState(false)

    const [companies, setCompanies] = useState(null)
    const [company, setCompany] = useState(null)
    const [dataToManage, setDataToManage] = useState(null)

    const actions = {
        ADD: {
            key: 1,
            label: displayMessage('ADD_COMPANY'),
        },
        EDIT: {
            key: 2,
            label: displayMessage('EDIT_COMPANY'),
        },
        REMOVE: {
            key: 3,
            label: displayMessage('REMOVE_COMPANY'),
        },
        SEE: {
            key: 4,
        },
        MANAGE: {
            key: 5,
        },
    }

    const backToInitState = () => {
        setIsAdding(false)
        setIsEditing(false)
        setIsLooking(false)
        setIsManaging(false)
        setCompany(null)
        setDataToManage(null)
        onChangeTitle(null)
        form.resetFields()
    }

    const handleOnClickAction = (key, _company) => {
        if (_company) {
            form.setFieldsValue(_company)
            setCompany(_company)
        }

        let newTitle
        let showButtons = true
        let back = () => backToInitState()
        switch (key) {
            case actions.ADD.key:
                newTitle = actions.ADD.label
                setIsAdding(true)
                break
            case actions.EDIT.key:
                newTitle = actions.EDIT.label

                setIsEditing(true)
                break
            case actions.SEE.key:
                newTitle = _company.name
                showButtons = false

                setIsLooking(true)
                break
            case actions.MANAGE.key:
                newTitle = company.name
                back = () => {
                    setIsManaging(false)
                    const newKey = isEditing ? actions.EDIT.key : actions.SEE.key
                    handleOnClickAction(newKey, company)
                }
                setIsManaging(true)
                manageCentrals()
                break
        }
        onChangeTitle(<NewTitle title={newTitle} showButtons={showButtons} back={() => back()} form={form}/>)
    }

    const createCompany = async (values) => {
        setShowLoading(true)
        try {
            await api.companies.create(values)
            showAlert(displayMessage('SUCCESS_CREATE_COMPANY'), EAlertTypes.SUCCESS)
            backToInitState()
            getCompanies()
        } catch (error) {
            console.log(error)
        } finally {
            setShowLoading(false)
        }
    }

    const editCompany = async (values) => {
        setShowLoading(true)
        try {
            await api.companies.update(company.id, values)
            showAlert(displayMessage('SUCCESS_EDIT_COMPANY'), EAlertTypes.SUCCESS)
            backToInitState()
            getCompanies()
        } catch (error) {
            console.log(error)
        } finally {
            setShowLoading(false)
        }
    }

    const deleteUser = async (id) => {
        setShowLoading(true)
        try {
            await api.companies.delete(id)
            showAlert(displayMessage('SUCCESS_DELETE_COMPANY'), EAlertTypes.SUCCESS)
            getCompanies()
        } catch (error) {
            console.log(error)
        } finally {
            setShowLoading(false)
        }
    }

    const getCompanies = async () => {
        setShowLoading(true)
        try {
            const { data } = await api.companies.list()
            setCompanies(data)
        } catch (error) {
            console.log(error)
        } finally {
            setShowLoading(false)
        }
    }

    const manageCentrals = async () => {
        setShowLoading(true)
        try {
            const { data } = await api.centrals.prevDataToManageCentrals(company.id)
            setDataToManage(data)
        } catch (error) {
            console.log(error)
        } finally {
            setShowLoading(false)
        }
    }

    const saveCentralsManagement = async (values) => {
        setShowLoading(true)

        try {
            const { data } = await api.centrals.manage(company.id, values)
            const newCompanies = [...companies]
            const newCompany = { ...company }
            const idx = newCompanies.findIndex((c) => c.id == company.id)
            newCompanies[idx].Centrals = data
            newCompany.Centrals = data

            setCompanies(newCompanies)
            form.resetFields()
            setDataToManage(null)
            setIsManaging(false)

            const key = isEditing ? actions.EDIT.key : actions.SEE.key
            handleOnClickAction(key, newCompany)
        } catch (error) {
            console.log(error)
        } finally {
            setShowLoading(false)
        }
    }

    useEffect(() => {
        getCompanies()
    }, [])

    return (
        <>
            {!isAdding && !isEditing && !isLooking && !isManaging && (
                <div className="flex flex-col gap-2">
                    <NewOption onClickAction={() => handleOnClickAction(actions.ADD.key)} message={displayMessage('CLICK_TO_ADD_A_NEW_COMPANY')} />

                    {companies &&
                        companies.map((company) => (
                            <Option
                                key={company.key}
                                iconImg={buildingsIcon}
                                onClickTitle={() => handleOnClickAction(actions.SEE.key, company)}
                                title={company.name}
                                onClickEdition={() => handleOnClickAction(actions.EDIT.key, company)}
                                onConfirmDelete={() => deleteUser(company.id)}
                                deleteMessage={displayMessage('DELETE_COMPANY_CONFIRM_MESSAGE')}
                                editTooltip={actions.EDIT.label}
                                removeTooltip={actions.REMOVE.label}
                            />
                        ))}
                </div>
            )}

            {isAdding && !isManaging && <NewCompany sendValues={createCompany} form={form} isAdding={true} setShowLoading={setShowLoading} />}
            {isEditing && !isManaging && <NewCompany sendValues={editCompany} form={form} />}
            {isLooking && !isManaging && <NewCompany form={form} onlyView={true} />}

            {(isEditing || isLooking) && !isManaging && <CentralsSection centrals={company.Centrals} companyId={company.id} onManageCentrals={() => handleOnClickAction(actions.MANAGE.key)} />}
            {isManaging && dataToManage && <ManageCentrals data={dataToManage} form={form} onManageCentrals={saveCentralsManagement} />}
        </>
    )
}
