export const AlertSvg = ({ color, size = 24 }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M10.3633 3.95728L2.25653 18.017C2.08938 18.3177 2.00094 18.6587 2.00001 19.0059C1.99907 19.3531 2.08567 19.6946 2.25119 19.9963C2.41671 20.2979 2.65538 20.5493 2.94346 20.7254C3.23153 20.9015 3.55897 20.9962 3.8932 21H20.1068C20.441 20.9962 20.7685 20.9015 21.0565 20.7254C21.3446 20.5493 21.5833 20.2979 21.7488 19.9963C21.9143 19.6946 22.0009 19.3531 22 19.0059C21.9991 18.6587 21.9106 18.3177 21.7435 18.017L13.6367 3.95728C13.466 3.66505 13.2258 3.42345 12.9391 3.25577C12.6524 3.08809 12.329 3 12 3C11.671 3 11.3476 3.08809 11.0609 3.25577C10.7742 3.42345 10.534 3.66505 10.3633 3.95728ZM11.2233 8.9C11.2233 8.45817 11.571 8.1 12 8.1C12.429 8.1 12.7767 8.45817 12.7767 8.9V14.1C12.7767 14.5418 12.429 14.9 12 14.9C11.571 14.9 11.2233 14.5418 11.2233 14.1V8.9ZM12 16.1C11.571 16.1 11.2233 16.4582 11.2233 16.9C11.2233 17.3418 11.571 17.7 12 17.7C12.429 17.7 12.7767 17.3418 12.7767 16.9C12.7767 16.4582 12.429 16.1 12 16.1Z"
                fill={color}
            />
        </svg>
    )
}
