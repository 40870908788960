import { useEffect, useState } from 'react'

export const ShowSVG = ({ icon, width, height }) => {
    const image = useImage(icon).image

    return <img width={width} height={height} src={image} />
}

const useImage = (fileName) => {
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)
    const [image, setImage] = useState(null)

    useEffect(() => {
        const fetchImage = async () => {
            try {
                const response = await import(`../../assets/icons-enum/${fileName}.svg`)
                setImage(response.default)
            } catch (err) {
                setError(err)
            } finally {
                setLoading(false)
            }
        }
        fetchImage()
    }, [fileName])
    return {
        loading,
        error,
        image,
    }
}
