const { displayMessage } = require('../helpers/messages')

module.exports = {
    ADM_GLOBAL: { value: 1, label: displayMessage('ADM_GLOBAL') }, // Usuário adm da própria Globalsonic
    MONITOR_GLOBAL: { value: 2, label: displayMessage('MONITOR_GLOBAL') }, // Monitor da própria Globalsonic
    INTEGRATOR: { value: 3, label: displayMessage('INTEGRATOR') }, //  Cliente da Globalsonic que presta serviço para os clientes finais (intermediário)
    ADM_APP: { value: 4, label: displayMessage('ADM_APP') }, // Adm do cliente da Globalsonic
    MONITOR_APP: { value: 5, label: displayMessage('MONITOR_APP') }, //  Monitor do cliente da Globalsonic

    // EMPLOYEE: { code: 0 }, // Usuário colaborador do app
    // CLIENT_ADMIN: { code: 1 }, // Usuário administrador do app
    // ORIGINAL_CLIENT: { code: 2 }, //Mesmos privilégios do CLIENT_ADMIN
    // PANEL_ADMIN: { code: 3 }, // Usuário que acessa o Painel Web - Possui permissão para gerenciar todos os outros users (que sao de app)
}
